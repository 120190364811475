import React from "react";
import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";
import {Talk, talksDuration} from "../../talks/Talk";
import {FormatBadgeGroup} from "../../formats/components/FormatBadgeGroup";
import {TopicBadgeGroup} from "../../topics/components/TopicBadgeGroup";
import {useOptions} from "../../app/Options";
import {TalkFilters} from "../../talks/TalkFilters";
import {Conference} from "../Conference";

const useStyles = makeStyles((theme: Theme) => ({
    indicator: {
        fontSize: theme.typography.fontSize * 2,
        color: theme.palette.primary.main,
    },
}));

type ConferenceStatsProps = {
    talks: Talk[];
    selection: Conference["selection"];
    filters: TalkFilters;
    onChangeFilters: (filters: TalkFilters) => void;
};

export const ConferenceStats = ({talks, selection, filters, onChangeFilters}: ConferenceStatsProps) => {
    const classes = useStyles();
    const selectedTalks = talks.filter(talk => selection.includes(talk.id));
    const {durationsById, formats, formatsById, topics} = useOptions();
    const submittedDuration = talksDuration(talks, durationsById);
    const selectedDuration = talksDuration(selectedTalks, durationsById);
    const submittedFormatIds = talks.map(talk => talk.formatId);
    const selectedFormatIds = selectedTalks.map(talk => talk.formatId);
    const submittedTopicIds = talks.map(talk => talk.topicId);
    const selectedTopicIds = selectedTalks.map(talk => talk.topicId);
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography>
                    <span className={classes.indicator}>{selectedTalks.length}</span> / {talks.length}{" "}
                    {selectedTalks.length > 1 ? "slots" : "slot"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    <span className={classes.indicator}>{selectedDuration}</span> / {submittedDuration}{" "}
                    {selectedDuration > 1 ? "minutes" : "minute"}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormatBadgeGroup
                    formatsById={formatsById}
                    formats={formats.filter(format => submittedFormatIds.includes(format.id))}
                    selectedFormatIds={selectedFormatIds}
                    selectedId={filters.formatId}
                    onSelectFormat={format => onChangeFilters({...filters, formatId: format?.id})}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TopicBadgeGroup
                    topics={topics.filter(topic => submittedTopicIds.includes(topic.id))}
                    selectedTopicIds={selectedTopicIds}
                    selectedId={filters.topicId}
                    onSelectTopic={topic => onChangeFilters({...filters, topicId: topic?.id})}
                />
            </Grid>
        </Grid>
    );
};
