import React from "react";
import {List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import {Conference, isOpenToSubmissions} from "../../conferences/Conference";
import {ConferenceText} from "../../conferences/components/ConferenceText";
import {Submission} from "../../submissions/Submission";
import {SubmissionConfirmationIcon} from "../../submissions/components/SubmissionConfirmationIcon";
import {SubmissionSelectionIcon} from "../../submissions/components/SubmissionSelectionIcon";
import {Talk} from "../Talk";
import {documentsById} from "../../app/data/Document";
import {CancelButton, ConfirmButton, DeleteButton} from "../../app/components/Button";

type TalkSubmissionListProps = {
    readonly: boolean;
    talk: Talk;
    submissions: Submission[];
    conferences: Conference[];
    onSelect: (conference: Conference) => void;
    onConfirm: (conference: Conference) => void;
    onCancel: (conference: Conference) => void;
    onDelete: (conference: Conference) => void;
};

export const TalkSubmissionList = ({
    talk,
    submissions,
    conferences,
    onSelect,
    onConfirm,
    onCancel,
    onDelete,
    readonly,
}: TalkSubmissionListProps) => {
    const conferencesById = documentsById(conferences);
    const isSelected = (conference: Conference) => conference.selection.includes(talk.id);
    return (
        <List>
            {submissions.length === 0 ? (
                <ListItem>
                    <ListItemText primary="Pas encore proposé à une conférence." />
                </ListItem>
            ) : (
                submissions.map(submission => {
                    const conference = conferencesById.get(submission.conferenceId);
                    return conference ? (
                        <ListItem key={submission.conferenceId} button onClick={() => onSelect(conference)}>
                            <ListItemIcon>
                                <SubmissionConfirmationIcon submission={submission} />
                            </ListItemIcon>
                            <ListItemIcon>
                                <SubmissionSelectionIcon selected={isSelected(conference)} />
                            </ListItemIcon>
                            <ListItemText>
                                <ConferenceText conference={conference} />
                            </ListItemText>
                            {readonly || !isOpenToSubmissions(conference) ? undefined : (
                                <ListItemSecondaryAction>
                                    {submission.confirmed ? (
                                        <CancelButton onClick={() => onCancel(conference)} />
                                    ) : (
                                        <ConfirmButton onClick={() => onConfirm(conference)} />
                                    )}
                                    <DeleteButton
                                        iconOnly
                                        disabled={submission.confirmed || isSelected(conference)}
                                        onClick={() => onDelete(conference)}
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    ) : (
                        <ListItem key={submission.conferenceId}>
                            <ListItemText>
                                <Typography color="error">
                                    La conférence {submission.conferenceId} n&apos;existe pas ou a été supprimée.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    );
                })
            )}
        </List>
    );
};
