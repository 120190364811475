import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Toolbar, Typography} from "@material-ui/core";
import {talkActions, useTalkFilters} from "../Talk.slice";
import {useTalkSaveAction, useTalkSearch} from "../Talk.hooks";
import {useRoutes} from "../../Routes";
import {TalkList} from "../components/TalkList";
import {TalkFiltersForm, TalkFiltersTooltip} from "../components/TalkFiltersForm";
import {TalkFilters} from "../TalkFilters";
import {Page} from "../../app/components/Page";
import {CreateButton} from "../../app/components/Button";
import {TalkFiltersText} from "../components/TalkFiltersText";
import {Loadable} from "../../app/components/Loadable";
import {Filler} from "../../app/components/Filler";
import {TalkForm} from "../components/TalkForm";
import {isResolved} from "../../app/data/State";

export const TalkListPage = () => {
    const routes = useRoutes();
    const filters = useTalkFilters();
    const searchState = useTalkSearch(filters);
    const dispatch = useDispatch();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [saveState, saveAction] = useTalkSaveAction();
    const [filterFormIsOpen, setFilterFormIsOpen] = useState(false);

    useEffect(() => {
        if (isResolved(saveState)) {
            routes.talk(saveState.result.id);
        }
    }, [saveState]);

    const setFilters = (filters: TalkFilters) => dispatch(talkActions.setFilters(filters));

    return (
        <Page
            title={<Typography variant="h6">Slots</Typography>}
            toolbar={
                <Toolbar>
                    <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    <Filler />
                    <TalkFiltersText filters={filters} />
                    <TalkFiltersTooltip onOpen={() => setFilterFormIsOpen(true)} />
                </Toolbar>
            }
        >
            <Loadable state={searchState}>
                {talks => <TalkList talks={talks} onSelect={talk => routes.talk(talk.id)} />}
            </Loadable>
            {createFormIsOpen ? (
                <TalkForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {filterFormIsOpen ? (
                <TalkFiltersForm filters={filters} onApply={setFilters} onClose={() => setFilterFormIsOpen(false)} />
            ) : undefined}
        </Page>
    );
};
