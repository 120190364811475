import React from "react";
import {Badge} from "@material-ui/core";
import {ItemGroup} from "../../app/components/ItemGroup";
import {TopicChip} from "./TopicChip";
import {Topic, topicCounts} from "../Topic";
import {useOptions} from "../../app/Options";

type TopicBadgeGroupProps = {
    size?: "small" | "medium";
    topics: Topic[];
    selectedTopicIds: string[];
    selectedId?: string;
    onSelectTopic?: (topic?: Topic) => void;
};

export const TopicBadgeGroup = ({size, topics, selectedTopicIds, selectedId, onSelectTopic}: TopicBadgeGroupProps) => {
    const {topicsById} = useOptions();
    const countByTopicId = topicCounts(selectedTopicIds, topicsById);
    return (
        <ItemGroup>
            {topics
                .sort((topic1, topic2) => topic1.label.localeCompare(topic2.label))
                .map(topic => (
                    <Badge key={topic.id} badgeContent={countByTopicId[topic.id] ?? 0} color="primary">
                        <TopicChip
                            topic={topic}
                            size={size}
                            selected={selectedId === topic.id}
                            onClick={
                                onSelectTopic
                                    ? () => onSelectTopic(selectedId === topic.id ? undefined : topic)
                                    : undefined
                            }
                        />
                    </Badge>
                ))}
        </ItemGroup>
    );
};
