import React from "react";
import {TopicChip} from "./TopicChip";
import {useOptions} from "../../app/Options";

type TopicTextProps = {
    topicId: string;
};

export const TopicText = ({topicId}: TopicTextProps) => {
    const {topicsById} = useOptions();
    const topic = topicsById.get(topicId);
    return topic ? <TopicChip topic={topic} size="small" /> : <></>;
};
