import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LinearProgress, Toolbar} from "@material-ui/core";
import {useRoutes} from "../../Routes";
import {talkBelongsTo, talkIsSelected} from "../Talk";
import {useAuthentication} from "../../auth/Authentication.provider";
import {isResolved, isRejected, isInitialOrPending} from "../../app/data/State";
import {ErrorNotifications} from "../../app/components/ErrorNotifications";
import {useTalk, useTalkDeleteAction, useTalkSaveAction} from "../Talk.hooks";
import {
    useSubmissionDeleteAction,
    useSubmissionSaveAction,
    useSubmissionsForTalk,
} from "../../submissions/Submission.hooks";
import {useConferenceSearch, useConferencesForSubmissions} from "../../conferences/Conference.hooks";
import {TalkText} from "../components/TalkText";
import {DeleteButton, SubmitButton, UpdateButton} from "../../app/components/Button";
import {Filler} from "../../app/components/Filler";
import {TalkDetail} from "../components/TalkDetail";
import {TalkSubmissionList} from "../components/TalkSubmissionList";
import {Page} from "../../app/components/Page";
import {TalkForm} from "../components/TalkForm";
import {TalkDelete} from "../components/TalkDelete";
import {SubmissionCreate} from "../../submissions/components/SubmissionCreate";
import {newConferencesToSubmitFilters} from "../../conferences/ConferenceFilters";
import {SubmissionConfirm} from "../../submissions/components/SubmissionConfirm";
import {Conference} from "../../conferences/Conference";
import {SubmissionCancel} from "../../submissions/components/SubmissionCancel";
import {SubmissionDelete} from "../../submissions/components/SubmissionDelete";

type TalkPageParams = {
    talkId: string;
};

export const TalkPage = () => {
    const routes = useRoutes();
    const {talkId} = useParams<TalkPageParams>();
    const talkState = useTalk(talkId);
    const submissionsState = useSubmissionsForTalk(talkId);
    const conferencesState = useConferencesForSubmissions(submissionsState);
    const authentication = useAuthentication();
    const [updateFormIsOpen, setUpdateFormIsOpen] = useState(false);
    const [updateState, updateAction] = useTalkSaveAction();
    const [deleteFormIsOpen, setDeleteFormIsOpen] = useState(false);
    const [deleteState, deleteAction] = useTalkDeleteAction();
    const [filters] = useState(newConferencesToSubmitFilters());
    const conferencesToSubmitState = useConferenceSearch(filters);
    const [createSubmissionFormIsOpen, setCreateSubmissionFormIsOpen] = useState(false);
    const [conferenceToConfirmSubmission, setConferenceToConfirmSubmission] = useState<Conference | undefined>(
        undefined,
    );
    const [conferenceToCancelSubmission, setConferenceToCancelSubmission] = useState<Conference | undefined>(undefined);
    const [saveSubmissionState, saveSubmissionAction] = useSubmissionSaveAction();
    const [conferenceToDeleteSubmission, setConferenceToDeleteSubmission] = useState<Conference | undefined>(undefined);
    const [deleteSubmissionState, deleteSubmissionAction] = useSubmissionDeleteAction();

    useEffect(() => {
        if (isResolved(updateState)) {
            setUpdateFormIsOpen(false);
        }
    }, [updateState]);

    useEffect(() => {
        if (isResolved(deleteState)) {
            routes.talks();
        }
    }, [deleteState]);

    useEffect(() => {
        if (isResolved(saveSubmissionState)) {
            setCreateSubmissionFormIsOpen(false);
            setConferenceToConfirmSubmission(undefined);
            setConferenceToCancelSubmission(undefined);
        }
    }, [saveSubmissionState]);

    useEffect(() => {
        if (isResolved(deleteSubmissionState)) {
            setConferenceToDeleteSubmission(undefined);
        }
    }, [deleteSubmissionState]);

    if (
        isInitialOrPending(talkState) ||
        isInitialOrPending(submissionsState) ||
        isInitialOrPending(conferencesState) ||
        isInitialOrPending(conferencesToSubmitState)
    ) {
        return <LinearProgress />;
    }

    if (
        isRejected(talkState) ||
        isRejected(submissionsState) ||
        isRejected(conferencesState) ||
        isRejected(conferencesToSubmitState)
    ) {
        return (
            <ErrorNotifications
                errorOrDoneStates={[talkState, submissionsState, conferencesState, conferencesToSubmitState]}
            />
        );
    }

    if (!talkState.result) {
        return null;
    }

    const talk = talkState.result;
    const submissions = submissionsState.result;
    const conferences = conferencesState.result;
    const conferencesToSubmit = conferencesToSubmitState.result;

    const readonly = !talkBelongsTo(talk, authentication.user);

    const findSubmissionFor = (conference: Conference | undefined) =>
        submissions.find(submission => submission.conferenceId === conference?.id);
    const submissionToConfirm = findSubmissionFor(conferenceToConfirmSubmission);
    const submissionToCancel = findSubmissionFor(conferenceToCancelSubmission);
    const submissionToDelete = findSubmissionFor(conferenceToDeleteSubmission);

    return (
        <Page
            title={<TalkText variant="h6" talk={talk} />}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <UpdateButton onClick={() => setUpdateFormIsOpen(true)} />
                        <SubmitButton onClick={() => setCreateSubmissionFormIsOpen(true)} />
                        <Filler />
                        <DeleteButton
                            disabled={talkIsSelected(talk, conferences)}
                            onClick={() => setDeleteFormIsOpen(true)}
                        />
                    </Toolbar>
                )
            }
        >
            <TalkDetail
                talk={talk}
                submissionsSection={
                    <TalkSubmissionList
                        readonly={readonly}
                        talk={talk}
                        submissions={submissions}
                        conferences={conferences}
                        onSelect={conference => routes.conference(conference.id)}
                        onConfirm={conference => setConferenceToConfirmSubmission(conference)}
                        onCancel={conference => setConferenceToCancelSubmission(conference)}
                        onDelete={conference => setConferenceToDeleteSubmission(conference)}
                    />
                }
            />
            {updateFormIsOpen ? (
                <TalkForm
                    talk={talk}
                    saveState={updateState}
                    onSave={updateAction}
                    onClose={() => setUpdateFormIsOpen(false)}
                />
            ) : undefined}
            {deleteFormIsOpen ? (
                <TalkDelete
                    talk={talk}
                    deleteState={deleteState}
                    onDelete={deleteAction}
                    onClose={() => setDeleteFormIsOpen(false)}
                />
            ) : undefined}
            {createSubmissionFormIsOpen ? (
                <SubmissionCreate
                    talk={talk}
                    conferences={conferencesToSubmit}
                    submissions={submissions}
                    saveState={saveSubmissionState}
                    onSubmit={saveSubmissionAction}
                    onClose={() => setCreateSubmissionFormIsOpen(false)}
                />
            ) : undefined}
            {conferenceToConfirmSubmission && submissionToConfirm ? (
                <SubmissionConfirm
                    talk={talk}
                    conference={conferenceToConfirmSubmission}
                    submission={submissionToConfirm}
                    saveState={saveSubmissionState}
                    onSave={saveSubmissionAction}
                    onClose={() => setConferenceToConfirmSubmission(undefined)}
                />
            ) : undefined}
            {conferenceToCancelSubmission && submissionToCancel ? (
                <SubmissionCancel
                    talk={talk}
                    conference={conferenceToCancelSubmission}
                    submission={submissionToCancel}
                    saveState={saveSubmissionState}
                    onSave={saveSubmissionAction}
                    onClose={() => setConferenceToCancelSubmission(undefined)}
                />
            ) : undefined}
            {conferenceToDeleteSubmission && submissionToDelete ? (
                <SubmissionDelete
                    talk={talk}
                    conference={conferenceToDeleteSubmission}
                    submission={submissionToDelete}
                    deleteState={deleteSubmissionState}
                    onDelete={deleteSubmissionAction}
                    onClose={() => setConferenceToDeleteSubmission(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
