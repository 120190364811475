import React from "react";
import {Avatar, Tooltip} from "@material-ui/core";
import {AvatarGroup} from "@material-ui/lab";
import {useOptions} from "../../app/Options";

type UserAvatarListProps = {
    userIds: string[];
};

export const UserAvatarList = ({userIds}: UserAvatarListProps) => {
    const {usersById} = useOptions();
    return (
        <AvatarGroup max={10}>
            {userIds.map(userId => {
                const user = usersById.get(userId);
                const displayName = user?.displayName ?? `L'utilisateur ${userId} n'existe pas ou a été supprimé.`;
                return (
                    <Tooltip key={userId} title={displayName}>
                        <Avatar alt={displayName} src={user?.photoURL} />
                    </Tooltip>
                );
            })}
        </AvatarGroup>
    );
};
