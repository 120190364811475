import React from "react";
import {Typography} from "@material-ui/core";
import {TalkFilters} from "../TalkFilters";
import {useOptions} from "../../app/Options";
import {User} from "../../users/User";

type TalkFiltersTextProps = {
    filters: TalkFilters;
};

export const TalkFiltersText = ({filters: {speakerIds, formatId, topicId, levelId}}: TalkFiltersTextProps) => {
    const {authenticatedUser, formatsById, topicsById, levelsById, usersById} = useOptions();
    const format = formatId ? formatsById.get(formatId) : undefined;
    const topic = topicId ? topicsById.get(topicId) : undefined;
    const level = levelId ? levelsById.get(levelId) : undefined;

    const filterText = (speakerNames: string[]) => {
        const onlyAuthenticatedUser = speakerNames.length == 1 && authenticatedUser.displayName === speakerNames[0];
        return onlyAuthenticatedUser
            ? "Mes slots"
            : `Slots de ${speakerNames.length > 0 ? speakerNames.join(", ") : "tout le monde"}`;
    };

    return (
        <Typography>
            {filterText(
                speakerIds
                    .map(speakerId => usersById.get(speakerId))
                    .filter((speaker): speaker is User => speaker !== undefined)
                    .map(speaker => speaker.displayName),
            )}
            {format ? ` - ${format.label}` : ""}
            {topic ? ` - ${topic.label}` : ""}
            {level ? ` - ${level.label}` : ""}
        </Typography>
    );
};
