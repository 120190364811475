import React from "react";
import {Resolved, Rejected, isRejected} from "../data/State";
import {ErrorNotification} from "./ErrorNotification";

type ErrorNotificationsProps = {
    errorOrDoneStates: (Resolved<unknown> | Rejected)[];
};

export const ErrorNotifications = ({errorOrDoneStates}: ErrorNotificationsProps) => {
    const errors = errorOrDoneStates.filter(
        (errorOrDoneState: Resolved<unknown> | Rejected): errorOrDoneState is Rejected => isRejected(errorOrDoneState),
    );
    return (
        <>
            {errors.map((error, index) => (
                <ErrorNotification key={index} error={error} />
            ))}
        </>
    );
};
