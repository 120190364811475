const firebaseOptionsByEnv = {
    development: {
        apiKey: "AIzaSyA1EWUzhWtojTsBa1-27XfsIOTJtJv-ips",
        authDomain: "ked-app-dev.firebaseapp.com",
        databaseURL: "https://ked-app-dev.firebaseio.com",
        projectId: "ked-app-dev",
        appId: "1:410695041454:web:dfb032b15a8b1a553b083f",
        region: "europe-west2",
    },
    production: {
        apiKey: "AIzaSyCw-A8YOq3S8Lu3IRe8_NMElbUz0McEpHc",
        authDomain: "ked-app-prd.firebaseapp.com",
        databaseURL: "https://ked-app-prd.firebaseio.com",
        projectId: "ked-app-prd",
        appId: "1:67424051776:web:81a8654f31e91d527875a5",
        region: "europe-west2",
    },
};

const productionOrigins = [
    "https://ked-app-prd.web.app",
    "https://ked-app-prd.firebaseapp.com",
    "https://ked.publicissapient.fr",
];

const isProd = productionOrigins.some(origin => origin === window.location.origin);

export const firebaseOptions = isProd ? firebaseOptionsByEnv.production : firebaseOptionsByEnv.development;
