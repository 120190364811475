import React from "react";
import {Chip, ChipProps} from "@material-ui/core";
import {FilterList as SelectedIcon} from "@material-ui/icons";
import {Topic as Topic} from "../Topic";

type TopicChipProps = {
    topic: Topic;
    size?: ChipProps["size"];
    onClick?: ChipProps["onClick"];
    selected?: boolean;
};

export const TopicChip = ({topic, size, onClick, selected}: TopicChipProps) => (
    <Chip
        size={size}
        label={topic.label}
        avatar={selected ? <SelectedIcon style={{color: "white"}} /> : undefined}
        clickable={onClick !== undefined}
        onClick={onClick}
        style={{color: "white", backgroundColor: topic.color}}
    />
);
