import {DraggableLocation, DropResult} from "react-beautiful-dnd";
import {ConferencePlanning} from "./ConferencePlanning";

export const maxItems = {
    groups: 20,
    tracks: 4,
    talks: 100,
};

type DragLocationParser = (location: string) => string[];
type DragLocationMerger<T> = (source: string, sourceIds: string[], target: string, targetIds: string[]) => T;

const dndReducer = <T>(
    source: DraggableLocation,
    target: DraggableLocation,
    maxItems: number,
    parseIds: DragLocationParser,
    mergeIds: DragLocationMerger<T>,
): T | undefined => {
    const sourceIds = parseIds(source.droppableId);
    const targetIds = parseIds(target.droppableId);
    if (source.droppableId !== target.droppableId && targetIds.length >= maxItems) {
        return;
    }
    const sourceClone = [...sourceIds];
    const targetClone = source.droppableId === target.droppableId ? sourceClone : [...targetIds];
    const [removed] = sourceClone.splice(source.index, 1);
    targetClone.splice(target.index, 0, removed);
    return mergeIds(source.droppableId, sourceClone, target.droppableId, targetClone);
};

export const conferencePlanningReducer = (planning: ConferencePlanning, action: DropResult): ConferencePlanning => {
    const {source, destination: target, reason, type} = action;

    if (reason === "CANCEL" || !target) {
        return planning;
    }

    if (type === "ROOT") {
        return (
            dndReducer<ConferencePlanning>(
                source,
                target,
                maxItems.groups,
                () => planning.roots.root.groupIds,
                (sourceRootId, sourceGroupIds) => ({
                    ...planning,
                    roots: {
                        root: {groupIds: sourceGroupIds},
                    },
                }),
            ) ?? planning
        );
    }

    if (type === "GROUP") {
        return (
            dndReducer(
                source,
                target,
                maxItems.tracks,
                groupId => planning.groups[groupId].trackIds,
                (sourceGroupId, sourceTrackIds, targetGroupId, targetTrackIds) => ({
                    ...planning,
                    groups: {
                        ...planning.groups,
                        [sourceGroupId]: {...planning.groups[sourceGroupId], trackIds: sourceTrackIds},
                        [targetGroupId]: {...planning.groups[targetGroupId], trackIds: targetTrackIds},
                    },
                }),
            ) ?? planning
        );
    }

    if (type === "TRACK") {
        return (
            dndReducer(
                source,
                target,
                maxItems.talks,
                trackId => planning.tracks[trackId].talkIds,
                (sourceTrackId, sourceTalkIds, targetTrackId, targetTalkIds) => ({
                    ...planning,
                    tracks: {
                        ...planning.tracks,
                        [sourceTrackId]: {...planning.tracks[sourceTrackId], talkIds: sourceTalkIds},
                        [targetTrackId]: {...planning.tracks[targetTrackId], talkIds: targetTalkIds},
                    },
                }),
            ) ?? planning
        );
    }

    return planning;
};
