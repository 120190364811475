import {newId} from "../app/data/Document";

export type Level = {
    id: string;
    value: number;
    label: string;
    emoji: string;
};

export const newLevel = (): Level => ({
    id: newId(),
    value: 0,
    label: "",
    emoji: "",
});

export type LevelErrors = {
    value: boolean;
    label: boolean;
    emoji: boolean;
};

export const levelErrors = (level: Level): LevelErrors => ({
    value: level.value <= 0,
    label: level.label.length === 0,
    emoji: level.emoji.length === 0,
});
