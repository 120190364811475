import React from "react";
import {Link, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {Room} from "../Room";
import {RoomText} from "./RoomText";
import {UpdateButton} from "../../app/components/Button";

type RoomListProps = {
    readonly?: boolean;
    rooms: Room[];
    onUpdate: (room: Room) => void;
};

export const RoomList = ({readonly, rooms, onUpdate}: RoomListProps) => (
    <List>
        {rooms.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucune salle n'est définie." />
            </ListItem>
        ) : (
            rooms.map(room => (
                <ListItem key={room.id}>
                    <ListItemText
                        primary={
                            <Link href={room.url} target="_blank" rel="noopener noreferrer">
                                <RoomText room={room} />
                            </Link>
                        }
                    />
                    {!readonly ? (
                        <ListItemSecondaryAction>
                            <UpdateButton iconOnly onClick={() => onUpdate(room)} />
                        </ListItemSecondaryAction>
                    ) : undefined}
                </ListItem>
            ))
        )}
    </List>
);
