import React from "react";
import {List, ListItem, ListItemIcon} from "@material-ui/core";
import {DeleteButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Talk} from "../Talk";
import {TalkIcon} from "./TalkIcon";
import {TalkText} from "./TalkText";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";

type TalkDeleteProps = {
    talk: Talk;
    deleteState: State<Talk>;
    onClose: () => void;
    onDelete: (talk: Talk) => void;
};

export const TalkDelete = ({talk, deleteState, onClose, onDelete}: TalkDeleteProps) => (
    <Dialog
        title="Supprimer le slot"
        actions={<DeleteButton onClick={() => onDelete(talk)} loading={isPending(deleteState)} />}
        onClose={onClose}
    >
        <List>
            <ListItem>
                <ListItemIcon>
                    <TalkIcon color="primary" />
                </ListItemIcon>
                <TalkText talk={talk} />
            </ListItem>
        </List>
        {isRejected(deleteState) ? <ErrorNotification error={deleteState} /> : undefined}
    </Dialog>
);
