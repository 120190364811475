import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {confCompanionFormatCodes} from "../Format";

type ConfCompanionFormatFieldProps = {
    value: string;
    error: boolean;
    onChange: (format: string) => void;
};

export const ConfCompanionFormatField = ({value, error, onChange}: ConfCompanionFormatFieldProps) => (
    <FormControl component="fieldset" fullWidth error={error}>
        <InputLabel>Code pour l&apos;application Conf Companion</InputLabel>
        <Select value={value} onChange={event => onChange(event.target.value as string)}>
            {confCompanionFormatCodes.map((confCompanionCode, index) => (
                <MenuItem key={index} value={confCompanionCode}>
                    {confCompanionCode}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
