import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Level, levelErrors, newLevel} from "../Level";

type LevelFormProps = {
    level?: Level;
    saveState: State<Level>;
    onSave: (level: Level) => void;
    onClose: () => void;
};

export const LevelForm = (props: LevelFormProps) => {
    const {level: existingLevel, saveState, onClose, onSave} = props;

    const [level, setLevel] = useState<Level>(existingLevel ?? newLevel());

    const errors = levelErrors(level);
    const hasError = errors.value || errors.label || errors.emoji;

    const onEdit = (updates: Partial<Level>) => setLevel({...level, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingLevel ? "Éditer le niveau" : "Créer un niveau"}
            actions={<SaveButton onClick={() => onSave(level)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Valeur"
                        placeholder="1, 2, 3 utilisé pour le tri en ordre ascendant de difficulté"
                        type="number"
                        value={level.value}
                        error={errors.value}
                        onChange={event => onEdit({value: parseInt(event.target.value) || 0})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Libellé"
                        placeholder="Facile, Confirmé, Expert, ..."
                        value={level.label}
                        error={errors.label}
                        onChange={event => onEdit({label: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Emoticône"
                        placeholder="🤩 🌶 🤯 ..."
                        value={level.emoji}
                        error={errors.emoji}
                        onChange={event => onEdit({emoji: event.target.value})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
