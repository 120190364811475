import React from "react";
import dayjs from "dayjs";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Card, CardActions, CardContent, CardHeader, Link, makeStyles, Theme, Typography} from "@material-ui/core";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@material-ui/lab";
import {Talk} from "../../talks/Talk";
import {DeleteButton} from "../../app/components/Button";
import {TalkText} from "../../talks/components/TalkText";
import {computeTalkTimings, ConferencePlanning, ConferencePlanningTrack} from "../ConferencePlanning";
import {timeFormat} from "../Conference";
import {documentsById} from "../../app/data/Document";
import {TopicBadgeGroup} from "../../topics/components/TopicBadgeGroup";
import {TalkInfoTooltip} from "../../talks/components/TalkInfoTooltip";
import {TalkPlenaryTooltip} from "../../talks/components/TalkPlenaryTooltip";
import {useOptions} from "../../app/Options";
import {RoomField} from "../../rooms/components/RoomField";

const useStyles = makeStyles((theme: Theme) => ({
    talks: {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
        minHeight: `${theme.spacing(10)}px`,
    },
    duration: {
        maxWidth: "75px",
    },
    talk: {
        minHeight: theme.spacing(1),
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
    },
    talkDetail: {
        borderStyle: "solid",
        borderWidth: `0 ${theme.spacing(1)}px 0 0`,
    },
    separator: {
        flexGrow: 1,
    },
    track: {
        minHeight: "100%",
    },
}));

type ConferencePlanningTrackEditorProps = {
    readonly: boolean;
    disabled: boolean;
    talks: Talk[];
    groupId: string;
    trackId: string;
    planning: ConferencePlanning;
    onUpdateTrack: (trackId: string, trackUpdate: Partial<ConferencePlanningTrack>) => void;
    onDeleteTrack?: (groupId: string, trackId: string) => void;
};

export const ConferencePlanningTrackEditor = ({
    readonly,
    disabled,
    talks,
    groupId,
    trackId,
    planning,
    onUpdateTrack,
    onDeleteTrack,
}: ConferencePlanningTrackEditorProps) => {
    const classes = useStyles();
    const {durationsById, topics, topicsById, rooms, roomsById} = useOptions();
    const talksById = documentsById(talks);

    const group = planning.groups[groupId];
    const track = planning.tracks[trackId];
    const talkIds = track.talkIds;

    const groupStartTime = dayjs(group.startTime, timeFormat);
    const groupPauseDuration = group.pauseDuration;
    const trackTalks = talkIds.map(talkId => talksById.get(talkId));
    const talksTiming = computeTalkTimings(durationsById, groupPauseDuration, talkIds, talksById);
    const trackEnd = talksTiming.length > 0 ? talksTiming[talksTiming.length - 1].end : 0;
    const trackTopicIds = trackTalks.filter((talk): talk is Talk => talk !== undefined).map(talk => talk.topicId);
    const trackRoom = roomsById.get(track.roomId);

    return (
        <Card elevation={2} className={classes.track}>
            {readonly ? (
                <CardHeader
                    title={
                        trackRoom ? (
                            <Link href={trackRoom.url} target="__blank" rel="noopener noreferrer">
                                {trackRoom.name}
                            </Link>
                        ) : (
                            <Typography color="primary" variant="h5">
                                🚧
                            </Typography>
                        )
                    }
                />
            ) : (
                <CardActions>
                    <RoomField
                        rooms={rooms}
                        disabled={disabled}
                        selectedId={track?.roomId}
                        onChange={roomId => onUpdateTrack(trackId, {roomId: roomId})}
                    />
                    <span className={classes.separator} />
                    <DeleteButton
                        iconOnly
                        disabled={disabled || !onDeleteTrack}
                        onClick={() => onDeleteTrack && onDeleteTrack(groupId, trackId)}
                    />
                </CardActions>
            )}
            <CardContent>
                <TopicBadgeGroup
                    size="small"
                    topics={topics.filter(topic => trackTopicIds.includes(topic.id))}
                    selectedTopicIds={trackTopicIds}
                />
                <Droppable isDropDisabled={readonly || disabled} droppableId={trackId} type="TRACK">
                    {droppableTalk => (
                        <Timeline align="left" ref={droppableTalk.innerRef} className={classes.talks}>
                            {talksTiming.map(({talkId, talk, start}, index) => (
                                <Draggable
                                    key={talkId}
                                    isDragDisabled={readonly || disabled}
                                    index={index}
                                    draggableId={talkId}
                                >
                                    {draggableTalk => (
                                        <TimelineItem
                                            className={classes.talk}
                                            ref={draggableTalk.innerRef}
                                            {...draggableTalk.draggableProps}
                                            {...draggableTalk.dragHandleProps}
                                        >
                                            <TimelineOppositeContent className={classes.duration}>
                                                {groupStartTime.add(start, "minute").format("LT")}
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            {talk ? (
                                                <TimelineContent
                                                    className={classes.talkDetail}
                                                    style={{
                                                        borderColor: topicsById.get(talk.topicId)?.color ?? "black",
                                                    }}
                                                >
                                                    <TalkText talk={talk} level />
                                                    <TalkInfoTooltip talk={talk} />
                                                    <TalkPlenaryTooltip talk={talk} />
                                                </TimelineContent>
                                            ) : (
                                                <TimelineContent
                                                    className={classes.talkDetail}
                                                    style={{borderColor: "black", borderStyle: "dashed"}}
                                                >
                                                    <Typography color="error" variant="body2">
                                                        Le slot {talkId} n&apos;existe pas ou a été supprimé.
                                                    </Typography>
                                                </TimelineContent>
                                            )}
                                        </TimelineItem>
                                    )}
                                </Draggable>
                            ))}
                            {droppableTalk.placeholder}
                            {talksTiming.length > 0 ? (
                                <TimelineItem className={classes.talk}>
                                    <TimelineOppositeContent className={classes.duration}>
                                        {groupStartTime.add(trackEnd, "minute").format("LT")}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography>Fin</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ) : undefined}
                        </Timeline>
                    )}
                </Droppable>
            </CardContent>
        </Card>
    );
};
