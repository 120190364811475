import React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {DeleteButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Conference} from "../../conferences/Conference";
import {ConferenceIcon} from "../../conferences/components/ConferenceIcon";
import {ConferenceText} from "../../conferences/components/ConferenceText";
import {Talk} from "../../talks/Talk";
import {TalkIcon} from "../../talks/components/TalkIcon";
import {TalkText} from "../../talks/components/TalkText";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Submission} from "../Submission";

type SubmissionDeleteProps = {
    talk: Talk;
    submission: Submission;
    conference: Conference;
    deleteState: State<Submission>;
    onDelete: (submission: Submission) => void;
    onClose: () => void;
};

export const SubmissionDelete = ({
    talk,
    submission,
    conference,
    deleteState,
    onDelete,
    onClose,
}: SubmissionDeleteProps) => (
    <Dialog
        title="Supprimer la proposition de slot"
        actions={<DeleteButton onClick={() => onDelete(submission)} loading={isPending(deleteState)} />}
        onClose={onClose}
    >
        <List>
            <ListItem>
                <ListItemIcon>
                    <TalkIcon color="primary" />
                </ListItemIcon>
                <TalkText talk={talk} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <ConferenceIcon color="primary" />
                </ListItemIcon>
                <ListItemText>
                    <ConferenceText conference={conference} />
                </ListItemText>
            </ListItem>
        </List>
        {isRejected(deleteState) ? <ErrorNotification error={deleteState} /> : undefined}
    </Dialog>
);
