import {CountById, DocumentsById, newId} from "../app/data/Document";

export type Topic = {
    id: string;
    label: string;
    color: string;
    confCompanionCode?: string;
};

export const newTopic = (): Topic => ({
    id: newId(),
    label: "",
    color: "",
});

export type TopicErrors = {
    label: boolean;
    color: boolean;
};

export const topicErrors = (topic: Topic): TopicErrors => ({
    label: topic.label.length === 0,
    color: topic.color.length === 0,
});

export const topicCounts = (topicIds: string[], topicsById: DocumentsById<Topic>): CountById =>
    topicIds
        .map(topicId => topicsById.get(topicId))
        .filter((topic): topic is Topic => topic !== undefined)
        .reduce((stats, topic) => ({...stats, [topic.id]: (stats[topic.id] ?? 0) + 1}), {} as CountById);

export const confCompanionTopicCodes: string[] = [
    "devops",
    "front",
    "agile",
    "back",
    "data",
    "mobile",
    "cloud",
    "iot",
    "craft",
];
