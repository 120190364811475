import firebase from "firebase";
import {User} from "./User";
import {firestoreDataConverter, firestore} from "../app/Firebase";

export const usersCollection = firestore.collection("users").withConverter(firestoreDataConverter<User>());

export const observeUsers = () => (
    setSuccess: (levels: User[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    usersCollection
        .orderBy("displayName")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveUser = async (user: User) => {
    await usersCollection.doc(user.id).set(user);
    return user;
};
