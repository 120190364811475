import React from "react";
import {List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {UpdateButton} from "../../app/components/Button";
import {Duration, format} from "../Duration";

type DurationListProps = {
    readonly?: boolean;
    durations: Duration[];
    onUpdate: (duration: Duration) => void;
};

export const DurationList = ({readonly, durations, onUpdate}: DurationListProps) => (
    <List>
        {durations.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucune durée n'est définie." />
            </ListItem>
        ) : (
            durations.map(duration => (
                <ListItem key={duration.id}>
                    <ListItemText primary={format(duration.value)} />
                    {readonly ? undefined : (
                        <ListItemSecondaryAction>
                            <UpdateButton iconOnly onClick={() => onUpdate(duration)} />
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))
        )}
    </List>
);
