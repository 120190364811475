import React, {useEffect, useState} from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {TopicList} from "../components/TopicList";
import {Page} from "../../app/components/Page";
import {useAuthentication} from "../../auth/Authentication.provider";
import {useTopics, useTopicSaveAction} from "../Topic.hooks";
import {Loadable} from "../../app/components/Loadable";
import {CreateButton} from "../../app/components/Button";
import {TopicForm} from "../components/TopicForm";
import {Topic} from "../Topic";
import {isResolved} from "../../app/data/State";

export const TopicListPage = () => {
    const authentication = useAuthentication();
    const topicsState = useTopics();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [updateFormTopic, setUpdateFormTopic] = useState<Topic | undefined>(undefined);
    const [saveState, saveAction] = useTopicSaveAction();

    useEffect(() => {
        if (isResolved(saveState)) {
            setCreateFormIsOpen(false);
            setUpdateFormTopic(undefined);
        }
    }, [saveState]);

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Thèmes</Typography>}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    </Toolbar>
                )
            }
        >
            <Loadable state={topicsState}>
                {topics => <TopicList readonly={readonly} topics={topics} onUpdate={setUpdateFormTopic} />}
            </Loadable>
            {createFormIsOpen ? (
                <TopicForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {updateFormTopic ? (
                <TopicForm
                    topic={updateFormTopic}
                    saveState={saveState}
                    onSave={saveAction}
                    onClose={() => setUpdateFormTopic(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
