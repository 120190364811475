import React from "react";
import {Talk} from "../Talk";
import {Tooltip, Typography} from "@material-ui/core";
import {Public as PlenaryIcon} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

type TalkPlenaryTooltipProps = {
    talk: Talk;
};

const useStyles = makeStyles(() => ({
    text: {
        whiteSpace: "pre-line",
    },
}));

export const TalkPlenaryTooltip = ({talk}: TalkPlenaryTooltipProps) => {
    const classes = useStyles();
    return talk.plenary ? (
        <Tooltip title={<Typography className={classes.text}>Plénière</Typography>}>
            <PlenaryIcon color="secondary" />
        </Tooltip>
    ) : (
        <></>
    );
};
