import React, {useEffect, useState} from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {LevelList} from "../components/LevelList";
import {Page} from "../../app/components/Page";
import {useAuthentication} from "../../auth/Authentication.provider";
import {useLevels, useLevelSaveAction} from "../Level.hooks";
import {Loadable} from "../../app/components/Loadable";
import {CreateButton} from "../../app/components/Button";
import {LevelForm} from "../components/LevelForm";
import {Level} from "../Level";
import {isResolved} from "../../app/data/State";

export const LevelListPage = () => {
    const authentication = useAuthentication();
    const levelsState = useLevels();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [updateFormLevel, setUpdateFormLevel] = useState<Level | undefined>(undefined);
    const [saveState, saveAction] = useLevelSaveAction();

    useEffect(() => {
        if (isResolved(saveState)) {
            setCreateFormIsOpen(false);
            setUpdateFormLevel(undefined);
        }
    }, [saveState]);

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Niveaux</Typography>}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    </Toolbar>
                )
            }
        >
            <Loadable state={levelsState}>
                {levels => <LevelList readonly={readonly} levels={levels} onUpdate={setUpdateFormLevel} />}
            </Loadable>
            {createFormIsOpen ? (
                <LevelForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {updateFormLevel ? (
                <LevelForm
                    level={updateFormLevel}
                    saveState={saveState}
                    onSave={saveAction}
                    onClose={() => setUpdateFormLevel(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
