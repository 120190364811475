import {Conference} from "../conferences/Conference";
import {Talk} from "../talks/Talk";
import {
    deleteSubmission,
    observeSubmissionsForConference,
    observeSubmissionsForTalk,
    saveSubmission,
} from "./Submission.repository";
import {useCallbackState, useObserverState} from "../app/data/State.hooks";

export const useSubmissionsForConference = (conferenceId: Conference["id"]) =>
    useObserverState(observeSubmissionsForConference(conferenceId), [conferenceId]);

export const useSubmissionsForTalk = (talkId: Talk["id"]) =>
    useObserverState(observeSubmissionsForTalk(talkId), [talkId]);

export const useSubmissionSaveAction = () => useCallbackState(saveSubmission, []);

export const useSubmissionDeleteAction = () => useCallbackState(deleteSubmission, []);
