import firebase from "firebase";
import {Room} from "./Room";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const roomsCollection = firestore.collection("rooms").withConverter(firestoreDataConverter<Room>());

export const observeRooms = () => (
    setSuccess: (rooms: Room[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    roomsCollection
        .orderBy("name")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveRoom = async (room: Room) => {
    await roomsCollection.doc(room.id).set(room);
    return room;
};
