import React, {useState} from "react";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import {FilterList as FilterIcon} from "@material-ui/icons";
import {SearchButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {UserListField} from "../../users/components/UserListField";
import {TalkFilters} from "../TalkFilters";
import {useOptions} from "../../app/Options";
import {FormatField} from "../../formats/components/FormatField";
import {LevelField} from "../../levels/components/LevelField";
import {TopicField} from "../../topics/components/TopicField";

type TalkFiltersFormProps = {
    filters: TalkFilters;
    onApply: (filters: TalkFilters) => void;
    onClose: () => void;
};

export const TalkFiltersForm = ({filters: initialFilters, onApply, onClose}: TalkFiltersFormProps) => {
    const {formats, levels, topics, users} = useOptions();
    const [filters, setFilters] = useState(initialFilters);

    const onUpdate = (updates: Partial<TalkFilters>) => setFilters({...filters, ...updates});

    const handleSearch = () => {
        onClose();
        onApply(filters);
    };

    return (
        <Dialog title="Filtrer les slots" actions={<SearchButton onClick={handleSearch} />} onClose={onClose}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <UserListField
                        label="Orateurs"
                        placeholder="Orateurs"
                        users={users}
                        valueIds={filters.speakerIds}
                        error={false}
                        onChange={speakerIds => onUpdate({speakerIds})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormatField
                        emptyLabel="Tous"
                        formats={formats}
                        selectedId={filters.formatId}
                        error={false}
                        onChange={formatId => onUpdate({formatId})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TopicField
                        emptyLabel="Tous"
                        topics={topics}
                        selectedId={filters.topicId}
                        error={false}
                        onChange={topicId => onUpdate({topicId})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LevelField
                        emptyLabel="Tous"
                        levels={levels}
                        selectedId={filters.levelId}
                        error={false}
                        onChange={levelId => onUpdate({levelId})}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

type TalkFiltersTooltipProps = {
    onOpen: () => void;
};

export const TalkFiltersTooltip = ({onOpen}: TalkFiltersTooltipProps) => (
    <Tooltip title="Filter les slots">
        <IconButton onClick={onOpen} color="primary">
            <FilterIcon />
        </IconButton>
    </Tooltip>
);
