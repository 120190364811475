import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Room} from "../Room";

type RoomFieldProps = {
    disabled: boolean;
    rooms: Room[];
    selectedId?: string;
    onChange: (roomId: string) => void;
};

const emptyValue = "-";

export const RoomField = ({disabled, rooms, selectedId = emptyValue, onChange}: RoomFieldProps) => (
    <FormControl component="fieldset" fullWidth>
        <InputLabel shrink>Salle</InputLabel>
        <Select
            disabled={disabled}
            defaultValue={emptyValue}
            value={selectedId ?? emptyValue}
            onChange={event => onChange(event.target.value as string)}
        >
            <MenuItem value={emptyValue}>{emptyValue}</MenuItem>
            {rooms.map(room => (
                <MenuItem key={room.id} value={room.id}>
                    {room.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
