import React from "react";
import ReactDOM from "react-dom";
import {Provider as ReduxProvider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import "./index.css";
import "typeface-roboto";
import {AuthenticationProvider} from "./auth/Authentication.provider";
import {Layout} from "./Layout";
import {ErrorBoundary} from "./app/components/ErrorBoundary";
import {store} from "./app/Redux";
import {theme} from "./Theme";
import {OptionsProvider} from "./app/Options";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/fr";

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.locale("fr");

const rootElement = document.getElementById("root");

const rootComponent = (
    <ErrorBoundary>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DayjsUtils}>
                <ReduxProvider store={store}>
                    <AuthenticationProvider>
                        <OptionsProvider>
                            <BrowserRouter>
                                <Layout />
                            </BrowserRouter>
                        </OptionsProvider>
                    </AuthenticationProvider>
                </ReduxProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </ErrorBoundary>
);

ReactDOM.render(rootComponent, rootElement);
