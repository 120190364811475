import React, {ErrorInfo} from "react";
import {ErrorMessage} from "./ErrorMessage";

type ErrorBoundaryProps = {
    children: React.ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
    message: string;
    stack: string;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: Readonly<ErrorBoundaryProps>) {
        super(props);
        this.state = {hasError: false, message: "", stack: ""};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({hasError: true, message: error.message, stack: errorInfo.componentStack});
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage path={location.pathname} message={this.state.message} stack={this.state.stack} />;
        }
        return this.props.children;
    }
}
