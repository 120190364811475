import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Dialog as MdDialog,
    DialogActions as MdDialogActions,
    DialogContent as MdDialogContent,
    DialogTitle as MdDialogTitle,
    Divider,
    IconButton,
    Theme,
    Typography,
} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        margin: `${theme.spacing(2)}px 0`,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
}));

type DialogProps = {
    open?: boolean;
    title: string;
    children: React.ReactNode;
    actions?: React.ReactNode;
    onClose?: () => void;
};

export const Dialog = ({open = true, title, children, actions, onClose}: DialogProps) => {
    const classes = useStyles();
    return (
        <MdDialog open={open} onClose={onClose} fullWidth scroll="paper">
            <MdDialogTitle disableTypography className={classes.title}>
                <Typography variant="h6">{title}</Typography>
                {onClose ? (
                    <IconButton color="inherit" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : undefined}
            </MdDialogTitle>
            <MdDialogContent className={classes.content}>{children}</MdDialogContent>
            {actions ? (
                <>
                    <Divider />
                    <MdDialogActions>{actions}</MdDialogActions>
                </>
            ) : undefined}
        </MdDialog>
    );
};
