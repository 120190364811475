import React from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {Talk} from "../Talk";
import {TalkText} from "./TalkText";
import {TopicText} from "../../topics/components/TopicText";

type TalkListProps = {
    talks: Talk[];
    onSelect: (talk: Talk) => void;
};

export const TalkList = ({talks, onSelect}: TalkListProps) => (
    <List>
        {talks.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucun slot ne correspond aux critères." />
            </ListItem>
        ) : (
            talks.map(talk => (
                <ListItem button key={talk.id} onClick={() => onSelect(talk)}>
                    <ListItemText>
                        <TalkText talk={talk} />
                        <TopicText topicId={talk.topicId} />
                    </ListItemText>
                </ListItem>
            ))
        )}
    </List>
);
