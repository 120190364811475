import {useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {newTalkFilters, TalkFilters} from "./TalkFilters";
import {RootState} from "../app/Redux";
import {Authentication} from "../auth/Authentication";
import {authenticationActions} from "../auth/Authentication.slice";

export type TalkSliceState = {
    filters: TalkFilters;
};

const initialState: TalkSliceState = {
    filters: newTalkFilters(),
};

export const {reducer: talkReducer, actions: talkActions} = createSlice({
    name: "talks",
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<TalkFilters>) => {
            state.filters = action.payload;
        },
    },
    extraReducers: builder =>
        builder.addCase(authenticationActions.setAuthentication, (state, action: PayloadAction<Authentication>) => {
            state.filters = newTalkFilters(action.payload.user);
        }),
});

export const useTalkFilters = (): TalkFilters => useSelector((state: RootState) => state.talks.filters);
