import React from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {Conference} from "../Conference";
import {ConferenceText} from "./ConferenceText";

type ConferenceListProps = {
    conferences: Conference[];
    onSelect: (conference: Conference) => void;
};

export const ConferenceList = ({conferences, onSelect}: ConferenceListProps) => (
    <List>
        {conferences.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucune conférence ne correspond aux critères." />
            </ListItem>
        ) : (
            conferences.map(conference => (
                <ListItem button key={conference.id} onClick={() => onSelect(conference)}>
                    <ListItemText primary={<ConferenceText conference={conference} />} />
                </ListItem>
            ))
        )}
    </List>
);
