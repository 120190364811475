import React, {createContext, ReactNode, useContext, useEffect} from "react";
import {LinearProgress} from "@material-ui/core";
import {AuthenticationForm} from "./components/AuthenticationForm";
import {isInitialOrPending, isRejected, isResolved} from "../app/data/State";
import {Authentication} from "./Authentication";
import {useSignIn, useTestEmailLinkAction} from "./Authentication.hooks";
import {useDispatch} from "react-redux";
import {authenticationActions} from "./Authentication.slice";
import {ErrorNotification} from "../app/components/ErrorNotification";

type AuthenticationProviderProps = {
    children: ReactNode;
};

const authenticationContext = createContext<Authentication>({} as Authentication);

export const useAuthentication = () => useContext<Authentication>(authenticationContext);

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
    const dispatch = useDispatch();
    const signInState = useSignIn();
    const [testEmailLinkState, testEmailLinkAction] = useTestEmailLinkAction();

    useEffect(() => {
        if (isResolved(signInState)) {
            if (signInState.result) {
                dispatch(authenticationActions.setAuthentication(signInState.result));
            } else {
                testEmailLinkAction(undefined);
            }
        }
    }, [signInState]);

    if (
        isInitialOrPending(signInState) ||
        (isResolved(signInState) &&
            !signInState.result &&
            (isInitialOrPending(testEmailLinkState) || (isResolved(testEmailLinkState) && testEmailLinkState.result)))
    ) {
        return <LinearProgress />;
    }

    if (isRejected(signInState) || !signInState.result) {
        return (
            <>
                <AuthenticationForm />
                {isRejected(signInState) ? <ErrorNotification error={signInState} /> : undefined}
                {isRejected(testEmailLinkState) ? <ErrorNotification error={testEmailLinkState} /> : undefined}
            </>
        );
    }

    return <authenticationContext.Provider value={signInState.result}>{props.children}</authenticationContext.Provider>;
};
