import React from "react";
import {Typography, TypographyVariant} from "@material-ui/core";
import {Talk} from "../Talk";
import {useOptions} from "../../app/Options";
import {format} from "../../durations/Duration";

type TalkTextProps = {
    variant?: TypographyVariant;
    speakers?: boolean;
    level?: boolean;
    talk: Talk;
};

const missingMetadata = "⚠️";

export const TalkText = ({talk, speakers, level, variant}: TalkTextProps) => {
    const {durationsById, formatsById, levelsById, usersById} = useOptions();
    const formatLabel = formatsById.get(talk.formatId)?.label;
    const levelEmoji = levelsById.get(talk.levelId)?.emoji;
    const durationValue = durationsById.get(talk.durationId)?.value;
    return (
        <Typography variant={variant}>
            {talk.title} - {formatLabel ?? missingMetadata} - {durationValue ? format(durationValue) : missingMetadata}
            {speakers
                ? ` - ${talk.speakerIds
                      .map(
                          speakerId =>
                              usersById.get(speakerId)?.displayName ??
                              `L'utilisateur ${speakerId} n'existe pas ou a été supprimé.`,
                      )
                      .join(", ")}`
                : undefined}
            {level ? ` - ${levelEmoji ?? missingMetadata}` : undefined}
        </Typography>
    );
};
