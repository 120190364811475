import React from "react";
import {Tooltip, Typography} from "@material-ui/core";
import {HowToReg as ConfirmedIcon} from "@material-ui/icons";
import {Submission} from "../Submission";

type SubmissionConfirmationIconProps = {
    submission: Submission;
};

export const SubmissionConfirmationIcon = ({submission: {confirmed}}: SubmissionConfirmationIconProps) => (
    <Tooltip
        title={
            <Typography>{confirmed ? "Confirmé par les orateurs" : "Pas encore confirmé par les orateurs"}</Typography>
        }
    >
        {confirmed ? <ConfirmedIcon color="primary" /> : <ConfirmedIcon color="disabled" />}
    </Tooltip>
);
