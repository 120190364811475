import React from "react";
import {
    Button as MdButton,
    ButtonProps as MdButtonProps,
    CircularProgress,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import {
    Add as CreateIcon,
    Delete as DeleteIcon,
    Done as SaveIcon,
    Edit as UpdateIcon,
    HowToReg as ConfirmIcon,
    Publish as PublishIcon,
    Search as SearchIcon,
    Send as SubmitIcon,
    Visibility as PreviewIcon,
} from "@material-ui/icons";

export type ButtonProps = {
    text: string;
    variant?: "text" | "outlined" | "contained";
    disabled?: boolean;
    loading?: boolean;
    iconOnly?: boolean;
    onClick: () => void;
} & Pick<MdButtonProps, "color" | "endIcon">;

export const Button = ({text, variant, loading, iconOnly = false, disabled, onClick, endIcon, color}: ButtonProps) => {
    if (iconOnly) {
        return (
            <Tooltip title={text}>
                <span>
                    <IconButton color={color ?? "primary"} onClick={onClick} disabled={disabled || loading}>
                        {loading ? <CircularProgress color="inherit" size={24} /> : endIcon}
                    </IconButton>
                </span>
            </Tooltip>
        );
    }
    return (
        <MdButton
            variant={variant}
            color={color ?? "primary"}
            endIcon={loading ? <CircularProgress color="inherit" size={24} /> : endIcon}
            onClick={onClick}
            disabled={disabled || loading}
        >
            {text}
        </MdButton>
    );
};

export const SaveButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Sauvegarder" endIcon={<SaveIcon />} />
);

export const CreateButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Créer" endIcon={<CreateIcon />} />
);

export const SubmitButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Proposer" endIcon={<SubmitIcon />} />
);

export const ConfirmButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Confirmer" endIcon={<ConfirmIcon />} />
);

export const CancelButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Annuler" color="secondary" endIcon={<ConfirmIcon />} />
);

export const SearchButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Rechercher" endIcon={<SearchIcon />} />
);

export const UpdateButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Éditer" endIcon={<UpdateIcon />} />
);

export const PublishButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Publier" endIcon={<PublishIcon />} />
);

export const DeleteButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Supprimer" endIcon={<DeleteIcon />} color="secondary" />
);

export const PreviewButton = (props: Omit<ButtonProps, "text">) => (
    <Button {...props} text="Prévisualisation" endIcon={<PreviewIcon />} />
);
