import React, {ReactNode} from "react";
import {LinearProgress} from "@material-ui/core";
import {isRejected, isInitialOrPending, State} from "../data/State";
import {ErrorNotification} from "./ErrorNotification";

type LoadableProps<RESULT> = {
    state: State<RESULT>;
    children: (loaded: RESULT) => ReactNode;
};

export const Loadable = <RESULT extends unknown>({state, children}: LoadableProps<RESULT>) => (
    <>
        {isInitialOrPending(state) ? (
            <LinearProgress />
        ) : isRejected(state) ? (
            <ErrorNotification error={state} />
        ) : (
            children(state.result)
        )}
    </>
);
