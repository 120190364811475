import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Duration, format} from "../Duration";

type DurationFieldProps = {
    durations: Duration[];
    selectedId: string;
    error: boolean;
    onChange: (durationId: string) => void;
};

export const DurationField = ({durations, selectedId, error, onChange}: DurationFieldProps) => (
    <FormControl component="fieldset" fullWidth error={error}>
        <InputLabel>Durée</InputLabel>
        <Select value={selectedId} onChange={event => onChange(event.target.value as string)}>
            {durations.map((duration, index) => (
                <MenuItem key={index} value={duration.id}>
                    {format(duration.value)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
