import React, {useState} from "react";
import {makeStyles, Theme} from "@material-ui/core";
import {useAuthentication} from "./auth/Authentication.provider";
import {Menu} from "./Menu";
import {Header} from "./Header";
import {Routes} from "./Routes";
import {useSignOutAction} from "./auth/Authentication.hooks";
import {ErrorNotification} from "./app/components/ErrorNotification";
import {isRejected} from "./app/data/State";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
    },
    head: {},
    body: {
        flexGrow: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "row",
    },
    nav: {
        backgroundColor: theme.palette.common.white,
    },
    main: {
        flexGrow: 1,
        overflowY: "auto",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
    },
}));

export const Layout = () => {
    const classes = useStyles();
    const authentication = useAuthentication();
    const [signOutState, signOutAction] = useSignOutAction();

    const [menuOpen, setMenuOpen] = useState(false);
    const onMenuToggle = () => setMenuOpen(!menuOpen);

    return (
        <div className={classes.root}>
            <header className={classes.head}>
                <Header user={authentication.user} signOut={signOutAction} onMenuToggle={onMenuToggle} />
            </header>
            <div className={classes.body}>
                <nav className={classes.nav}>
                    <Menu menuOpen={menuOpen} onMenuToggle={onMenuToggle} />
                </nav>
                <main className={classes.main}>
                    <Routes />
                </main>
            </div>
            {isRejected(signOutState) ? <ErrorNotification error={signOutState} /> : undefined}
        </div>
    );
};
