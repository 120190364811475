import React from "react";
import {Chip, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {User} from "../User";

export type UserListFieldProps = {
    users: User[];
    label?: string;
    placeholder?: string;
    required?: User;
    valueIds: string[];
    error: boolean;
    onChange: (valueIds: string[]) => void;
};

export const UserListField = ({label, placeholder, users, required, valueIds, error, onChange}: UserListFieldProps) => {
    const selected = users.filter(user => valueIds.find(valueId => valueId === user.id));
    const setSelected = (users: User[]) => onChange(users.map(user => user.id));
    return (
        <Autocomplete
            fullWidth
            multiple
            filterSelectedOptions
            options={users}
            getOptionLabel={user => user.displayName}
            value={selected}
            onChange={(event, users) => {
                if (required) {
                    setSelected([required, ...users.filter(user => user.id !== required.id)]);
                } else {
                    setSelected(users);
                }
            }}
            renderTags={(users, getTagProps) =>
                users.map((user, index) => (
                    <Chip
                        key={user.id}
                        {...getTagProps({index})}
                        label={user.displayName}
                        disabled={required && required.id === user.id}
                    />
                ))
            }
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    label={label ?? "Utilisateurs"}
                    placeholder={placeholder ?? "Utilisateurs"}
                />
            )}
        />
    );
};
