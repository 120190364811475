import dayjs from "dayjs";
import {User} from "../users/User";
import {newId} from "../app/data/Document";
import {ConferencePlanning, newPlanning} from "./ConferencePlanning";
import {Talk} from "../talks/Talk";

export type Conference = {
    id: string;
    title: string;
    description: string;
    date: string;
    organizerIds: string[];
    selection: Talk["id"][];
    planning: ConferencePlanning;
};

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm:ss";

const today = dayjs().startOf("day");

export const newConference = (authenticatedUser: User): Conference => ({
    id: newId(),
    title: "",
    description: "",
    date: today.format(dateFormat),
    organizerIds: [authenticatedUser.id],
    selection: [],
    planning: newPlanning(),
});

export type ConferenceErrors = {
    title: boolean;
    organizerIds: boolean;
};

export const conferenceErrors = (conference: Conference, user: User): ConferenceErrors => ({
    title: conference.title.length === 0,
    organizerIds: !conferenceBelongsTo(conference, user),
});

export const conferenceBelongsTo = (conference: Conference, user: User) =>
    conference.organizerIds.some(organizerId => organizerId === user.id);

export const isOpenToSubmissions = (conference: Conference) => {
    const conferenceDate = dayjs(conference.date, dateFormat);
    return conferenceDate.isSame(today, "day") || conferenceDate.isAfter(today, "day");
};
