import React, {useState} from "react";
import {Divider, Grid, makeStyles, Toolbar, Typography} from "@material-ui/core";
import {UserAvatarList} from "../../users/components/UserAvatarList";
import {Conference} from "../Conference";
import {Talk} from "../../talks/Talk";
import {UserList} from "../../users/components/UserList";
import {useOptions} from "../../app/Options";
import {applyTalkFilters, newTalkFilters, TalkFilters} from "../../talks/TalkFilters";
import {User} from "../../users/User";
import {ConferenceStats} from "./ConferenceStats";
import {DeleteButton, UpdateButton} from "../../app/components/Button";
import {Filler} from "../../app/components/Filler";
import {Page} from "../../app/components/Page";

type ConferenceDetailProps = {
    readonly: boolean;
    conference: Conference;
    talks: Talk[];
    onUpdate: () => void;
    onDelete: () => void;
};

const useStyles = makeStyles(() => ({
    description: {
        whiteSpace: "pre-line",
    },
}));

export const ConferenceDetail = ({readonly, conference, talks, onUpdate, onDelete}: ConferenceDetailProps) => {
    const classes = useStyles();
    const {usersById} = useOptions();

    const [filters, setFilters] = useState<TalkFilters>(newTalkFilters());

    const filteredTalks = talks.filter(talk => applyTalkFilters(talk, filters));

    const speakers = Array.from(new Set(filteredTalks.flatMap(talk => talk.speakerIds)))
        .map(speakerId => usersById.get(speakerId))
        .filter((speaker): speaker is User => speaker !== undefined)
        .sort((speaker1, speaker2) => speaker1.displayName.localeCompare(speaker2.displayName));

    return (
        <Page
            toolbar={
                <>
                    <Toolbar variant="dense">
                        <UpdateButton onClick={onUpdate} disabled={readonly} />
                        <Filler />
                        <DeleteButton onClick={onDelete} disabled={readonly} />
                    </Toolbar>
                    <Divider />
                    <ConferenceStats
                        talks={talks}
                        selection={conference.selection}
                        filters={filters}
                        onChangeFilters={setFilters}
                    />
                </>
            }
        >
            <Grid container spacing={2}>
                {conference.description && (
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.description}>
                            Description
                        </Typography>
                        <p>{conference.description}</p>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="h6">Organisateurs</Typography>
                    <UserAvatarList userIds={conference.organizerIds} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Orateurs ({speakers.length})</Typography>
                    <UserList users={speakers} />
                </Grid>
            </Grid>
        </Page>
    );
};
