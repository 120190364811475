import {User} from "../users/User";
import {DocumentsById, newId} from "../app/data/Document";
import {Duration} from "../durations/Duration";
import {Conference} from "../conferences/Conference";

export type Talk = {
    id: string;
    title: string;
    plenary: boolean;
    formatId: string;
    durationId: string;
    topicId: string;
    pitch: string;
    info: string;
    levelId: string;
    speakerIds: string[];
};

export const newTalk = (authenticatedUser: User): Talk => ({
    id: newId(),
    title: "",
    plenary: false,
    formatId: "",
    durationId: "",
    topicId: "",
    levelId: "",
    pitch: "",
    info: "",
    speakerIds: [authenticatedUser.id],
});

export type TalkErrors = {
    title: boolean;
    formatId: boolean;
    durationId: boolean;
    topicId: boolean;
    levelId: boolean;
    pitch: boolean;
    speakerIds: boolean;
};

export const talkErrors = (talk: Talk, user: User): TalkErrors => ({
    title: talk.title.length === 0,
    formatId: talk.formatId.length === 0,
    durationId: talk.durationId.length === 0,
    topicId: talk.topicId.length === 0,
    levelId: talk.levelId.length === 0,
    pitch: talk.pitch.length === 0,
    speakerIds: !talkBelongsTo(talk, user),
});

export const talkBelongsTo = (talk: Talk, user: User) => talk.speakerIds.some(speakerId => speakerId === user.id);

export const talkIsSelected = (talk: Talk, conferences: Conference[]) =>
    conferences.some(conference => conference.selection.includes(talk.id));

export const talksDuration = (talks: Talk[], durationsById: DocumentsById<Duration>) =>
    talks
        .map(talk => durationsById.get(talk.durationId))
        .filter((duration): duration is Duration => duration !== undefined)
        .reduce((acc, duration) => acc + duration.value, 0);
