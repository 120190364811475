import React from "react";
import {
    Avatar,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from "@material-ui/core";
import {ExitToApp as SignOutIcon, Menu as MenuIcon} from "@material-ui/icons";
import {User} from "./users/User";
import {menuWidth} from "./Menu";
import {KedLogo} from "./app/components/KedLogo";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    menuButton: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    logo: {
        width: menuWidth,
    },
    separator: {
        flexGrow: 1,
    },
    displayName: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
}));

type HeadersProps = {
    user: User;
    signOut: () => void;
    onMenuToggle: () => void;
};

export const Header = (props: HeadersProps) => {
    const {user} = props;
    const classes = useStyles();
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

    return (
        <Toolbar className={classes.root}>
            <IconButton className={classes.menuButton} color="inherit" onClick={props.onMenuToggle}>
                <MenuIcon />
            </IconButton>
            <span className={classes.logo}>
                <KedLogo size="small" color="text" />
            </span>
            <span className={classes.separator} />
            <MenuItem onClick={event => setMenuAnchor(event.currentTarget)}>
                <Typography className={classes.displayName}>{user.displayName}</Typography>
                <IconButton size="small">
                    <Avatar src={user.photoURL} />
                </IconButton>
            </MenuItem>
            <Menu
                keepMounted
                anchorEl={menuAnchor}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={props.signOut}>
                    <ListItemIcon>
                        <SignOutIcon />
                    </ListItemIcon>
                    <ListItemText>Déconnexion</ListItemText>
                </MenuItem>
            </Menu>
        </Toolbar>
    );
};
