import {useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConferenceFilters, newConferenceListFilters} from "./ConferenceFilters";
import {RootState} from "../app/Redux";

export type ConferenceSliceState = {
    filters: ConferenceFilters;
};

const initialState: ConferenceSliceState = {
    filters: newConferenceListFilters(),
};

export const {reducer: conferenceReducer, actions: conferenceActions} = createSlice({
    name: "conferences",
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<ConferenceFilters>) => {
            state.filters = action.payload;
        },
    },
});

export const useConferenceFilters = () => useSelector((state: RootState) => state.conferences.filters);
