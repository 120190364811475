import React from "react";
import {Button, List, ListItem, Typography} from "@material-ui/core";
import {Dialog} from "./Dialog";

type ErrorMessageProps = {
    path: string;
    message: string;
    stack: string;
};

export const ErrorMessage = ({path, message, stack}: ErrorMessageProps) => (
    <Dialog
        title="Une erreur inconnue s'est produite"
        actions={
            <Button color="primary" href="/">
                Retour à l&apos;écran d&apos;acceuil
            </Button>
        }
    >
        <List>
            <ListItem>
                <Typography>
                    <b>Chemin</b>: {path}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <b>Message</b>: {message}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <b>Trace</b>: {stack}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <a
                        href="https://github.com/xebia-france/ked.publicissapient.fr/issues"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Veuillez saisir une entrée avec les étapes qui ont menées à l&apos;erreur et les messages
                        ci-dessus
                    </a>
                </Typography>
            </ListItem>
        </List>
    </Dialog>
);
