import React, {createContext, useContext} from "react";
import {LinearProgress} from "@material-ui/core";
import {useFormats} from "../formats/Format.hooks";
import {useDurations} from "../durations/Duration.hooks";
import {useTopics} from "../topics/Topic.hooks";
import {useLevels} from "../levels/Level.hooks";
import {useUserGroups} from "../userGroups/UserGroup.hooks";
import {useUsers} from "../users/User.hooks";
import {DocumentsById, documentsById} from "./data/Document";
import {isRejected, isInitialOrPending} from "./data/State";
import {useRooms} from "../rooms/Room.hooks";
import {Duration} from "../durations/Duration";
import {Format} from "../formats/Format";
import {Level} from "../levels/Level";
import {Topic} from "../topics/Topic";
import {User} from "../users/User";
import {Room} from "../rooms/Room";
import {UserGroup} from "../userGroups/UserGroup";
import {ErrorNotifications} from "./components/ErrorNotifications";
import {useAuthentication} from "../auth/Authentication.provider";

export type Options = {
    authenticatedUser: User;
    durations: Duration[];
    durationsById: DocumentsById<Duration>;
    formats: Format[];
    formatsById: DocumentsById<Format>;
    levels: Level[];
    levelsById: DocumentsById<Level>;
    topics: Topic[];
    topicsById: DocumentsById<Topic>;
    rooms: Room[];
    roomsById: DocumentsById<Room>;
    users: User[];
    usersById: DocumentsById<User>;
    userGroups: UserGroup[];
    userGroupsById: DocumentsById<UserGroup>;
};

export const optionsContext = createContext<Options>({} as Options);

export const useOptions = () => useContext<Options>(optionsContext);

type OptionsProviderProps = {
    children: React.ReactNode;
};

export const OptionsProvider = ({children}: OptionsProviderProps) => {
    const authentication = useAuthentication();
    const durationsState = useDurations();
    const formatsState = useFormats();
    const levelsState = useLevels();
    const topicsState = useTopics();
    const roomsState = useRooms();
    const usersState = useUsers();
    const userGroupsState = useUserGroups();

    if (
        isInitialOrPending(durationsState) ||
        isInitialOrPending(formatsState) ||
        isInitialOrPending(levelsState) ||
        isInitialOrPending(topicsState) ||
        isInitialOrPending(roomsState) ||
        isInitialOrPending(usersState) ||
        isInitialOrPending(userGroupsState)
    ) {
        return <LinearProgress />;
    }

    if (
        isRejected(durationsState) ||
        isRejected(formatsState) ||
        isRejected(levelsState) ||
        isRejected(topicsState) ||
        isRejected(roomsState) ||
        isRejected(usersState) ||
        isRejected(userGroupsState)
    ) {
        return (
            <ErrorNotifications
                errorOrDoneStates={[
                    durationsState,
                    formatsState,
                    levelsState,
                    topicsState,
                    roomsState,
                    usersState,
                    userGroupsState,
                ]}
            />
        );
    }

    const options: Options = {
        authenticatedUser: authentication.user,
        durations: durationsState.result,
        durationsById: documentsById(durationsState.result),
        formats: formatsState.result,
        formatsById: documentsById(formatsState.result),
        levels: levelsState.result,
        levelsById: documentsById(levelsState.result),
        topics: topicsState.result,
        topicsById: documentsById(topicsState.result),
        rooms: roomsState.result,
        roomsById: documentsById(roomsState.result),
        users: usersState.result,
        usersById: documentsById(usersState.result),
        userGroups: userGroupsState.result,
        userGroupsById: documentsById(userGroupsState.result),
    };

    return <optionsContext.Provider value={options}>{children}</optionsContext.Provider>;
};
