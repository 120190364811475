import React from "react";
import {Avatar, Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {User} from "../User";

type UserListProps = {
    users: User[];
};

export const UserList = ({users}: UserListProps) => (
    <List>
        {users.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucun orateur n'est encore enregistré." />
            </ListItem>
        ) : (
            users.map(user => (
                <ListItem key={user.id}>
                    <ListItemIcon>
                        <Avatar alt={user?.displayName} src={user?.photoURL} />
                    </ListItemIcon>
                    <ListItemText
                        primary={user.displayName}
                        secondary={
                            <Link href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
                                {user.email}
                            </Link>
                        }
                    />
                </ListItem>
            ))
        )}
    </List>
);
