import {DocumentsById, newId} from "../app/data/Document";
import {Talk} from "../talks/Talk";
import {Conference} from "./Conference";
import {Duration} from "../durations/Duration";

export type ConferencePlanningRoot = {
    groupIds: string[];
};

export type ConferencePlanningGroup = {
    startTime: string;
    pauseDuration: number;
    trackIds: string[];
};

export type ConferencePlanningTrack = {
    roomId: string;
    talkIds: string[];
};

export const pauseDurations = [0, 5, 10, 15];

export type ConferencePlanning = {
    roots: {
        root: ConferencePlanningRoot;
    };
    groups: {
        [groupId: string]: ConferencePlanningGroup;
    };
    tracks: {
        [trackId: string]: ConferencePlanningTrack;
    };
};

export const newPlanningGroup = (trackId: string): ConferencePlanningGroup => ({
    startTime: "09:00:00",
    pauseDuration: 5,
    trackIds: [trackId],
});

export const newPlanningTrack = () => ({
    roomId: "-",
    talkIds: [],
});

export const newPlanning = (): ConferencePlanning => {
    const groupId = newId();
    const trackId = newId();
    return {
        roots: {
            root: {
                groupIds: [groupId],
            },
        },
        groups: {
            [groupId]: newPlanningGroup(trackId),
        },
        tracks: {
            [trackId]: newPlanningTrack(),
        },
    };
};

export const cleanPlanning = (planning: ConferencePlanning, talks: Talk[]): ConferencePlanning => {
    const talkIds = talks.map(talk => talk.id);
    return {
        roots: planning.roots,
        groups: planning.groups,
        tracks: Object.fromEntries(
            Object.entries(planning.tracks).map(([trackId, track]) => {
                const cleanedTalkIds = track.talkIds.filter(talkId => talkIds.includes(talkId));
                return [trackId, {...track, talkIds: cleanedTalkIds}];
            }),
        ),
    };
};

export const insertGroup = ({roots, groups, tracks}: ConferencePlanning): ConferencePlanning => {
    const groupId = newId();
    const trackId = newId();
    return {
        roots: {
            ...roots,
            root: {
                groupIds: [...roots.root.groupIds, groupId],
            },
        },
        groups: {
            ...groups,
            [groupId]: newPlanningGroup(trackId),
        },
        tracks: {
            ...tracks,
            [trackId]: newPlanningTrack(),
        },
    };
};

export const updateGroup = (
    planning: ConferencePlanning,
    groupId: string,
    groupUpdate: Partial<ConferencePlanningGroup>,
): ConferencePlanning => {
    return {
        ...planning,
        groups: {
            ...planning.groups,
            [groupId]: {...planning.groups[groupId], ...groupUpdate},
        },
    };
};

export const deleteGroup = (planning: ConferencePlanning, deletedGroupId: string): ConferencePlanning => {
    const {roots, groups, tracks} = planning;
    const groupIds = roots.root.groupIds;
    if (groupIds.length <= 1 || !groupIds.includes(deletedGroupId)) {
        return planning;
    }
    const cleanedGroupIds = groupIds.filter(groupId => groupId !== deletedGroupId);
    const orphanTrackIds = groups[deletedGroupId].trackIds;
    const orphanTalkIds = orphanTrackIds.flatMap(orphanTrackId => tracks[orphanTrackId].talkIds);
    const defaultGroupId = cleanedGroupIds[0];
    const defaultGroup = groups[defaultGroupId];
    const defaultTrackId = defaultGroup.trackIds[0];
    const defaultTrack = tracks[defaultTrackId];
    const cleanedGroups = Object.fromEntries(Object.entries(groups).filter(([groupId]) => groupId !== deletedGroupId));
    const cleanedTracks = Object.fromEntries(
        Object.entries(tracks).filter(([trackId]) => !orphanTrackIds.includes(trackId)),
    );
    return {
        roots: {
            root: {
                groupIds: cleanedGroupIds,
            },
        },
        groups: cleanedGroups,
        tracks: {
            ...cleanedTracks,
            [defaultTrackId]: {
                ...defaultTrack,
                talkIds: [...defaultTrack.talkIds, ...orphanTalkIds],
            },
        },
    };
};

export const insertTrack = ({roots, groups, tracks}: ConferencePlanning, groupId: string): ConferencePlanning => {
    const trackId = newId();
    const group = groups[groupId];
    return {
        roots,
        groups: {
            ...groups,
            [groupId]: {
                ...group,
                trackIds: [...group.trackIds, trackId],
            },
        },
        tracks: {
            ...tracks,
            [trackId]: newPlanningTrack(),
        },
    };
};

export const updateTrack = (
    planning: ConferencePlanning,
    trackId: string,
    trackUpdate: Partial<ConferencePlanningTrack>,
): ConferencePlanning => ({
    ...planning,
    tracks: {
        ...planning.tracks,
        [trackId]: {
            ...planning.tracks[trackId],
            ...trackUpdate,
        },
    },
});

export const deleteTrack = (
    planning: ConferencePlanning,
    groupId: string,
    deletedTrackId: string,
): ConferencePlanning => {
    const {roots, groups, tracks} = planning;
    const group = groups[groupId];
    if (!group) {
        return planning;
    }
    const trackIds = group.trackIds;
    if (trackIds.length <= 1 || !trackIds.includes(deletedTrackId)) {
        return planning;
    }
    const remainingTrackIds = trackIds.filter(trackId => trackId !== deletedTrackId);
    const orphanTalkIds = tracks[deletedTrackId].talkIds;
    const defaultTrackId = remainingTrackIds[0];
    const defaultTrack = tracks[defaultTrackId];
    const cleanedTracks = Object.fromEntries(Object.entries(tracks).filter(([trackId]) => trackId !== deletedTrackId));
    return {
        roots,
        groups: {
            ...groups,
            [groupId]: {
                ...group,
                trackIds: remainingTrackIds,
            },
        },
        tracks: {
            ...cleanedTracks,
            [defaultTrackId]: {
                ...defaultTrack,
                talkIds: [...defaultTrack.talkIds, ...orphanTalkIds],
            },
        },
    };
};

export const cleanSelection = (conference: Conference, talks: Talk[]): Conference => {
    const talkIds = talks.map(talk => talk.id);
    return {
        ...conference,
        selection: conference.selection.filter(talkId => talkIds.includes(talkId)),
    };
};

export const selectTalks = (conference: Conference, selection: Conference["selection"]): Conference => {
    const {roots, groups, tracks} = conference.planning;
    const oldSelection = Object.values(tracks).flatMap(track => track.talkIds);
    const newSelection = selection.filter(talkId => !oldSelection.includes(talkId));
    const cleanedTracks = Object.fromEntries(
        Object.entries(tracks).map(([trackId, track]) => {
            const cleanedTalkIds = track.talkIds.filter(talkId => selection.includes(talkId));
            return [trackId, {...track, talkIds: cleanedTalkIds}];
        }),
    );
    const defaultGroupId = roots.root.groupIds[0];
    const defaultGroup = groups[defaultGroupId];
    const defaultTrackId = defaultGroup.trackIds[0];
    const defaultTrack = cleanedTracks[defaultTrackId];
    const updatedPlanning: ConferencePlanning = {
        roots,
        groups,
        tracks: {
            ...cleanedTracks,
            [defaultTrackId]: {
                ...defaultTrack,
                talkIds: [...defaultTrack.talkIds, ...newSelection],
            },
        },
    };
    return {
        ...conference,
        selection,
        planning: updatedPlanning,
    };
};

export type TalkTiming = {
    talkId: string;
    talk?: Talk;
    start: number;
    end: number;
};

export const computeTalkTimings = (
    durationsById: DocumentsById<Duration>,
    pauseDuration: number,
    talkIds: Talk["id"][],
    talksById: DocumentsById<Talk>,
): TalkTiming[] => {
    let nextStart = 0;
    return talkIds.map(talkId => {
        const talk = talksById.get(talkId);
        const talkDuration = talk ? durationsById.get(talk.durationId)?.value ?? 0 : 0;
        const talkStart = nextStart;
        const talkEnd = talkStart + talkDuration;
        nextStart = talkEnd + pauseDuration;
        return {talkId, talk, start: talkStart, end: talkEnd};
    });
};
