import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Duration, durationErrors, newDuration} from "../Duration";

type DurationFormProps = {
    duration?: Duration;
    saveState: State<Duration>;
    onSave: (duration: Duration) => void;
    onClose: () => void;
};

export const DurationForm = ({duration: existingDuration, saveState, onClose, onSave}: DurationFormProps) => {
    const [duration, setDuration] = useState<Duration>(existingDuration ?? newDuration());

    const errors = durationErrors(duration);
    const hasError = errors.value;

    const onEdit = (updates: Partial<Duration>) => setDuration({...duration, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingDuration ? "Éditer la durée" : "Créer une durée"}
            actions={<SaveButton onClick={() => onSave(duration)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Valeur (min)"
                        placeholder="5"
                        type="number"
                        value={duration.value}
                        error={errors.value}
                        onChange={event => onEdit({value: parseInt(event.target.value) || 0})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
