import React from "react";
import {Format} from "../Format";
import {Chip, ChipProps} from "@material-ui/core";
import {FilterList as SelectedIcon} from "@material-ui/icons";

type FormatChipProps = {
    format: Format;
    size?: ChipProps["size"];
    onClick?: ChipProps["onClick"];
    selected?: boolean;
};

export const FormatChip = ({format, size, onClick, selected}: FormatChipProps) => (
    <Chip
        size={size}
        label={format.label}
        avatar={selected ? <SelectedIcon /> : undefined}
        clickable={onClick !== undefined}
        onClick={onClick}
    />
);
