import {CountById, DocumentsById, newId} from "../app/data/Document";

export type Format = {
    id: string;
    label: string;
    description: string;
    confCompanionCode: string;
};

export const newFormat = (): Format => ({
    id: newId(),
    label: "",
    description: "",
    confCompanionCode: "",
});

export type FormatErrors = {
    label: boolean;
    confCompanionCode: boolean;
};

export const formatErrors = (format: Format): FormatErrors => ({
    label: format.label.length === 0,
    confCompanionCode: format.confCompanionCode.length === 0,
});

export const formatCounts = (formatIds: string[], formatsById: DocumentsById<Format>): CountById =>
    formatIds
        .map(formatId => formatsById.get(formatId))
        .filter((format): format is Format => format !== undefined)
        .reduce((stats, format) => ({...stats, [format.id]: (stats[format.id] ?? 0) + 1}), {} as CountById);

export const confCompanionFormatCodes: string[] = [
    "handson",
    "workshop",
    "closing",
    "party",
    "lunch",
    "opening",
    "breakfast",
    "break",
    "keynote",
    "talk",
    "rex",
];
