import React from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {Page} from "../../app/components/Page";
import {UserList} from "../components/UserList";
import {Loadable} from "../../app/components/Loadable";
import {isResolved} from "../../app/data/State";
import {useUsers} from "../User.hooks";
import {Filler} from "../../app/components/Filler";

export const UserListPage = () => {
    const usersState = useUsers();
    return (
        <Page
            title={<Typography variant="h6">Orateurs</Typography>}
            toolbar={
                <Toolbar>
                    <Filler />
                    {isResolved(usersState) ? (
                        <Typography>
                            {usersState.result.length}{" "}
                            {usersState.result.length > 1 ? "orateurs déjà enregistrés" : "orateur déjà enregistré"} sur
                            cette application. 👏
                        </Typography>
                    ) : undefined}
                </Toolbar>
            }
        >
            <Loadable state={usersState}>{users => <UserList users={users} />}</Loadable>
        </Page>
    );
};
