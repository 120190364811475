import {configureStore} from "@reduxjs/toolkit";
import {authenticationReducer} from "../auth/Authentication.slice";
import {conferenceReducer} from "../conferences/Conference.slice";
import {talkReducer} from "../talks/Talk.slice";

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        conferences: conferenceReducer,
        talks: talkReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
