import React from "react";
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import {Warning as WarningIcon} from "@material-ui/icons";
import {CancelButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Conference} from "../../conferences/Conference";
import {ConferenceIcon} from "../../conferences/components/ConferenceIcon";
import {ConferenceText} from "../../conferences/components/ConferenceText";
import {Talk} from "../../talks/Talk";
import {TalkIcon} from "../../talks/components/TalkIcon";
import {TalkText} from "../../talks/components/TalkText";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Submission} from "../Submission";

type SubmissionCancelProps = {
    talk: Talk;
    submission: Submission;
    conference: Conference;
    saveState: State<Submission>;
    onSave: (submission: Submission) => void;
    onClose: () => void;
};

export const SubmissionCancel = ({talk, submission, conference, saveState, onSave, onClose}: SubmissionCancelProps) => {
    const onCancel = () => onSave({...submission, confirmed: false});
    const isLoading = isPending(saveState);
    return (
        <Dialog
            title="Annuler la proposition de slot"
            actions={<CancelButton onClick={onCancel} loading={isLoading} />}
            onClose={onClose}
        >
            <List>
                <ListItem>
                    <ListItemIcon>
                        <TalkIcon color="primary" />
                    </ListItemIcon>
                    <TalkText talk={talk} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ConferenceIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                        <ConferenceText conference={conference} />
                    </ListItemText>
                </ListItem>
                {conference.selection.includes(talk.id) ? (
                    <ListItem>
                        <ListItemIcon>
                            <WarningIcon color="error" />
                        </ListItemIcon>
                        <Typography color="error">
                            Ce slot a été selectionné par les organisateurs. Pensez à les informer que vous ne pouvez
                            plus présenter ce slot.
                        </Typography>
                    </ListItem>
                ) : undefined}
            </List>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
