import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Format} from "../Format";

type FormatFieldProps = {
    emptyLabel?: string;
    formats: Format[];
    selectedId?: string;
    error: boolean;
    onChange: (formatId?: string) => void;
};

const emptyValue = "-";

export const FormatField = ({emptyLabel, formats, selectedId, error, onChange}: FormatFieldProps) => (
    <FormControl component="fieldset" fullWidth error={error}>
        <InputLabel>Format</InputLabel>
        <Select
            value={selectedId ?? emptyValue}
            onChange={event => {
                const formatId = event.target.value as string;
                onChange(formatId === emptyValue ? undefined : formatId);
            }}
        >
            {emptyLabel ? (
                <MenuItem value={emptyValue}>
                    <em>{emptyLabel}</em>
                </MenuItem>
            ) : undefined}
            {formats.map((format, index) => (
                <MenuItem key={index} value={format.id}>
                    {format.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
