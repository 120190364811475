import {Tooltip, Typography} from "@material-ui/core";
import {EventAvailable as SelectedIcon, EventBusy as AvailableIcon} from "@material-ui/icons";
import React from "react";

type SubmissionSelectionIconProps = {
    selected: boolean;
};

export const SubmissionSelectionIcon = ({selected}: SubmissionSelectionIconProps) => (
    <Tooltip
        title={
            <Typography>
                {selected ? "Programmé par les organisateurs" : "Pas encore programmé par les organisateurs"}
            </Typography>
        }
    >
        {selected ? <SelectedIcon color="primary" /> : <AvailableIcon color="disabled" />}
    </Tooltip>
);
