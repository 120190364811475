import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Format, formatErrors, newFormat} from "../Format";
import {ConfCompanionFormatField} from "./ConfCompanionFormatField";

type FormatFormProps = {
    format?: Format;
    saveState: State<Format>;
    onSave: (format: Format) => void;
    onClose: () => void;
};

export const FormatForm = (props: FormatFormProps) => {
    const {format: existingFormat, saveState, onClose, onSave} = props;

    const [format, setFormat] = useState<Format>(existingFormat ?? newFormat());

    const errors = formatErrors(format);
    const hasError = errors.label || errors.confCompanionCode;

    const onEdit = (updates: Partial<Format>) => setFormat({...format, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingFormat ? "Éditer le format" : "Créer un format"}
            actions={<SaveButton onClick={() => onSave(format)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Libellé"
                        placeholder="Démonstration, Présentation, Atelier, ..."
                        value={format.label}
                        error={errors.label}
                        onChange={event => onEdit({label: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Description"
                        placeholder="Une présentation formelle consiste en..."
                        value={format.description}
                        onChange={event => onEdit({description: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ConfCompanionFormatField
                        value={format.confCompanionCode}
                        error={errors.confCompanionCode}
                        onChange={confCompanionCode => onEdit({confCompanionCode})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
