import React, {useState} from "react";
import {Container, makeStyles, TextField, Typography} from "@material-ui/core";
import {Button} from "../../app/components/Button";
import {KedLogo} from "../../app/components/KedLogo";
import {isPending, isRejected, isResolved, Rejected, Resolved} from "../../app/data/State";
import {Authentication, validEmailRegex} from "../Authentication";
import {useSendEmailLinkAction} from "../Authentication.hooks";
import {ErrorNotification} from "../../app/components/ErrorNotification";

const useStyles = makeStyles(theme => ({
    paper: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(2),
    },
}));

export type AuthenticationFormProps = {
    signInState: Resolved<Authentication | undefined> | Rejected;
};

export const AuthenticationForm = () => {
    const classes = useStyles();
    const [sendEmailLinkState, sendEmailLinkAction] = useSendEmailLinkAction();
    const [email, setEmail] = useState("");

    const error = email.length > 0 && !validEmailRegex.test(email);

    return (
        <Container component={"main"} maxWidth="xs" className={classes.paper}>
            <KedLogo size="large" />
            <TextField
                fullWidth
                name="email"
                type="email"
                error={error}
                label="E-mail"
                placeholder="bob@publicissapient.{fr,com}"
                value={email}
                onChange={event => setEmail(event.target.value)}
            />
            {isResolved(sendEmailLinkState) ? (
                <Typography>Le lien de connexion a été envoyé à cette adresse.</Typography>
            ) : undefined}
            {isRejected(sendEmailLinkState) ? <ErrorNotification error={sendEmailLinkState} /> : undefined}
            <Button
                disabled={error || email.length === 0}
                text="Envoyer le lien"
                variant="contained"
                onClick={() => sendEmailLinkAction(email)}
                loading={isPending(sendEmailLinkState)}
            />
        </Container>
    );
};
