import {newId} from "../app/data/Document";

export type Room = {
    id: string;
    name: string;
    url: string;
};

export const newRoom = (): Room => ({
    id: newId(),
    name: "",
    url: "",
});

export type RoomErrors = {
    name: boolean;
    url: boolean;
};

export const roomErrors = ({name, url}: Room): RoomErrors => ({
    name: name.length === 0,
    url: url.length === 0,
});
