import React, {useState} from "react";
import dayjs from "dayjs";
import {Grid, TextField} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Conference, conferenceErrors, dateFormat, newConference} from "../Conference";
import {UserListField} from "../../users/components/UserListField";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {useOptions} from "../../app/Options";
import {findOrganizers} from "../../userGroups/UserGroup";

export type ConferenceFormProps = {
    conference?: Conference;
    saveState: State<Conference>;
    onSave: (conference: Conference) => void;
    onClose: () => void;
};

export const ConferenceForm = ({conference: existingConference, saveState, onClose, onSave}: ConferenceFormProps) => {
    const {authenticatedUser, users, userGroups} = useOptions();

    const [conference, setConference] = useState<Conference>(existingConference ?? newConference(authenticatedUser));
    const organizers = findOrganizers(users, userGroups);

    const errors = conferenceErrors(conference, authenticatedUser);
    const hasError = errors.title || errors.organizerIds;
    const formatDate = (date: dayjs.Dayjs) => date.format(dateFormat);

    const onEdit = (updates: Partial<Conference>) => setConference({...conference, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingConference ? "Éditer la conférence" : "Créer une conférence"}
            actions={<SaveButton onClick={() => onSave(conference)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="title"
                        label="Titre"
                        placeholder="Titre de la conférence"
                        value={conference.title}
                        error={errors.title}
                        onChange={event => onEdit({title: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        fullWidth
                        id="date"
                        label="Date"
                        placeholder="Date"
                        format={dateFormat}
                        value={dayjs(conference.date, dateFormat)}
                        onChange={date => onEdit({date: formatDate(date as dayjs.Dayjs)})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="description"
                        multiline
                        label="Description"
                        placeholder="Cette conférence portera sur..."
                        rows={5}
                        value={conference.description}
                        onChange={event => onEdit({description: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserListField
                        label="Organisateurs"
                        placeholder="Organisateurs"
                        users={organizers}
                        required={authenticatedUser}
                        valueIds={conference.organizerIds}
                        error={errors.organizerIds}
                        onChange={organizerIds => onEdit({organizerIds})}
                    />
                </Grid>
                {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
            </Grid>
        </Dialog>
    );
};
