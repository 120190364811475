import React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ConfirmButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Conference} from "../../conferences/Conference";
import {ConferenceIcon} from "../../conferences/components/ConferenceIcon";
import {ConferenceText} from "../../conferences/components/ConferenceText";
import {Talk} from "../../talks/Talk";
import {TalkIcon} from "../../talks/components/TalkIcon";
import {TalkText} from "../../talks/components/TalkText";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Submission} from "../Submission";

type SubmissionConfirmProps = {
    talk: Talk;
    submission: Submission;
    conference: Conference;
    saveState: State<Submission>;
    onSave: (submission: Submission) => void;
    onClose: () => void;
};

export const SubmissionConfirm = ({
    talk,
    submission,
    conference,
    saveState,
    onSave,
    onClose,
}: SubmissionConfirmProps) => {
    const onConfirm = () => onSave({...submission, confirmed: true});
    const isLoading = isPending(saveState);
    return (
        <Dialog
            title="Confirmer la proposition de slot"
            actions={<ConfirmButton onClick={onConfirm} loading={isLoading} />}
            onClose={onClose}
        >
            <List>
                <ListItem>
                    <ListItemIcon>
                        <TalkIcon color="primary" />
                    </ListItemIcon>
                    <TalkText talk={talk} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <ConferenceIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                        <ConferenceText conference={conference} />
                    </ListItemText>
                </ListItem>
            </List>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
