import React from "react";
import {Badge} from "@material-ui/core";
import {ItemGroup} from "../../app/components/ItemGroup";
import {FormatChip} from "./FormatChip";
import {DocumentsById} from "../../app/data/Document";
import {Format, formatCounts} from "../Format";

type FormatBadgeGroupProps = {
    formatsById: DocumentsById<Format>;
    formats: Format[];
    selectedFormatIds: string[];
    selectedId?: string;
    onSelectFormat?: (format?: Format) => void;
};

export const FormatBadgeGroup = ({
    formatsById,
    formats,
    selectedFormatIds,
    selectedId,
    onSelectFormat,
}: FormatBadgeGroupProps) => {
    const countByFormatId = formatCounts(selectedFormatIds, formatsById);
    return (
        <ItemGroup>
            {formats
                .sort((format1, format2) => format1.label.localeCompare(format2.label))
                .map(format => (
                    <Badge key={format.id} badgeContent={countByFormatId[format.id] ?? 0} color="primary">
                        <FormatChip
                            format={format}
                            size="medium"
                            selected={selectedId === format.id}
                            onClick={
                                onSelectFormat
                                    ? () => onSelectFormat(selectedId === format.id ? undefined : format)
                                    : undefined
                            }
                        />
                    </Badge>
                ))}
        </ItemGroup>
    );
};
