import React from "react";
import {List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {Level} from "../Level";
import {UpdateButton} from "../../app/components/Button";

type LevelListProps = {
    readonly?: boolean;
    levels: Level[];
    onUpdate: (level: Level) => void;
};

export const LevelList = ({readonly, levels, onUpdate}: LevelListProps) => (
    <List>
        {levels.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucun niveau n'est défini." />
            </ListItem>
        ) : (
            levels.map(level => (
                <ListItem key={level.id}>
                    <ListItemText primary={`${level.label} ${level.emoji}`} />
                    {readonly ? undefined : (
                        <ListItemSecondaryAction>
                            <UpdateButton iconOnly onClick={() => onUpdate(level)} />
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))
        )}
    </List>
);
