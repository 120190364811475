import {useEffect, useState} from "react";
import {Talk} from "./Talk";
import {TalkFilters} from "./TalkFilters";
import {deleteTalk, findTalks, observeTalk, observeTalks, saveTalk} from "./Talk.repository";
import {pending, resolved, errorFromReason, isResolved, State, initial} from "../app/data/State";
import {Submission} from "../submissions/Submission";
import {useCallbackState, useObserverState} from "../app/data/State.hooks";

export const useTalkSearch = (talkFilters: TalkFilters) => {
    const {formatId, levelId, speakerIds, topicId} = talkFilters;
    return useObserverState(observeTalks(talkFilters), [formatId, levelId, speakerIds, topicId]);
};

export const useTalk = (talkId: Talk["id"]) => useObserverState(observeTalk(talkId), [talkId]);

export const useTalksForSubmissions = (submissionsState: State<Submission[]>) => {
    const [state, setState] = useState<State<Talk[]>>(initial());
    useEffect(() => {
        if (!isResolved(submissionsState)) {
            setState(pending());
            return;
        }
        const submissions = submissionsState.result;
        const talkIds = submissions.map(({talkId}) => talkId);
        findTalks(talkIds)
            .then(talks => setState(resolved(talks)))
            .catch(reason => setState(errorFromReason(reason)));
    }, [submissionsState]);
    return state;
};

export const useTalkSaveAction = () => useCallbackState(saveTalk, []);

export const useTalkDeleteAction = () => useCallbackState(deleteTalk, []);
