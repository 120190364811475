import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Topic} from "../Topic";

type TopicFieldProps = {
    emptyLabel?: string;
    topics: Topic[];
    selectedId?: string;
    error: boolean;
    onChange: (topicId?: string) => void;
};

const emptyValue = "-";

export const TopicField = ({emptyLabel, topics, selectedId, error, onChange}: TopicFieldProps) => (
    <FormControl component="fieldset" fullWidth error={error}>
        <InputLabel>Thème</InputLabel>
        <Select
            value={selectedId ?? emptyValue}
            onChange={event => {
                const topicId = event.target.value as string;
                onChange(topicId === emptyValue ? undefined : topicId);
            }}
        >
            {emptyLabel ? (
                <MenuItem value={emptyValue}>
                    <em>{emptyLabel}</em>
                </MenuItem>
            ) : undefined}
            {topics.map((topic, index) => (
                <MenuItem key={index} value={topic.id}>
                    {topic.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
