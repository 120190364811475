import {User} from "../users/User";
import {Talk} from "./Talk";

export type TalkFilters = {
    speakerIds: string[];
    formatId?: string;
    levelId?: string;
    topicId?: string;
    orderBy: keyof Talk;
    limit: number;
};

export const newTalkFilters = (authenticatedUser?: User): TalkFilters => ({
    speakerIds: authenticatedUser ? [authenticatedUser.id] : [],
    orderBy: "title",
    limit: 20,
});

export const applyTalkFilters = (talk: Talk, filters: TalkFilters) =>
    (!filters.formatId || filters.formatId === talk.formatId) && (!filters.topicId || filters.topicId === talk.topicId);
