import React, {useState} from "react";
import {
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from "@material-ui/core";
import {Info as InfoIcon, Warning as WarningIcon} from "@material-ui/icons";
import {Conference} from "../../conferences/Conference";
import {ConferenceIcon} from "../../conferences/components/ConferenceIcon";
import {SubmitButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Talk} from "../../talks/Talk";
import {TalkIcon} from "../../talks/components/TalkIcon";
import {TalkText} from "../../talks/components/TalkText";
import {isPending, isRejected, State} from "../../app/data/State";
import {newSubmission, Submission} from "../Submission";
import {ConferenceText} from "../../conferences/components/ConferenceText";
import {ErrorNotification} from "../../app/components/ErrorNotification";

type SubmissionCreateState = Conference | undefined;

type SubmissionCreateProps = {
    talk: Talk;
    conferences: Conference[];
    submissions: Submission[];
    saveState: State<Submission>;
    onSubmit: (submission: Submission, conference: Conference) => void;
    onClose: () => void;
};

export const SubmissionCreate = ({
    talk,
    conferences,
    submissions,
    saveState,
    onSubmit,
    onClose,
}: SubmissionCreateProps) => {
    const [selected, setSelected] = useState<SubmissionCreateState>(undefined);
    const [confirmed, setConfirmed] = useState(false);

    const doSubmit = () => selected && onSubmit(newSubmission(selected, talk, confirmed), selected);

    const submissionConferenceIds = submissions.map(submission => submission.conferenceId);
    const availableConferences = conferences.filter(conference => !submissionConferenceIds.includes(conference.id));

    const isLoading = isPending(saveState);

    const onSelect = (conference: Conference) => () => {
        setConfirmed(false);
        setSelected(selected === conference ? undefined : conference);
    };

    return (
        <Dialog
            title="Proposer un slot"
            actions={<SubmitButton onClick={doSubmit} disabled={!selected} loading={isLoading} />}
            onClose={onClose}
        >
            <List>
                <ListItem>
                    <ListItemIcon>
                        <TalkIcon color="primary" />
                    </ListItemIcon>
                    <TalkText talk={talk} />
                </ListItem>
            </List>
            <Divider />
            <List>
                {availableConferences.length === 0 ? (
                    <ListItem>
                        <ListItemIcon>
                            <WarningIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Aucune conférence n'est disponible" />
                    </ListItem>
                ) : (
                    availableConferences.map(conference => (
                        <ListItem
                            key={conference.id}
                            button
                            disabled={isLoading}
                            selected={selected && selected.id === conference.id}
                            onClick={onSelect(conference)}
                        >
                            <ListItemIcon>
                                <ConferenceIcon
                                    color={selected && selected.id === conference.id ? "primary" : "disabled"}
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <ConferenceText conference={conference} />
                            </ListItemText>
                        </ListItem>
                    ))
                )}
                <ListItem disabled={!selected}>
                    <ListItemIcon>
                        <InfoIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>Je confirme que mon slot est prêt</ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            disabled={!selected}
                            checked={confirmed}
                            onChange={event => setConfirmed(event.target.checked)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
