import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {newRoom, Room, roomErrors} from "../Room";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";

type RoomFormProps = {
    room?: Room;
    saveState: State<Room>;
    onSave: (room: Room) => void;
    onClose: () => void;
};

export const RoomForm = (props: RoomFormProps) => {
    const {room: existingRoom, saveState, onClose, onSave} = props;

    const [room, setRoom] = useState<Room>(existingRoom ?? newRoom());

    const errors = roomErrors(room);
    const hasError = errors.name || errors.url;

    const onEdit = (updates: Partial<Room>) => setRoom({...room, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingRoom ? "Éditer la salle" : "Créer une salle"}
            actions={<SaveButton onClick={() => onSave(room)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom"
                        placeholder="Arial"
                        value={room.name}
                        error={errors.name}
                        onChange={event => onEdit({name: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="URL"
                        placeholder="https://meet.google.com/xyz"
                        value={room.url}
                        error={errors.url}
                        onChange={event => onEdit({url: event.target.value})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
