import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {levelText} from "./LevelText";
import {Level} from "../Level";

type LevelFieldProps = {
    emptyLabel?: string;
    levels: Level[];
    selectedId?: string;
    error: boolean;
    onChange: (levelId?: string) => void;
};

const emptyValue = "-";

export const LevelField = ({emptyLabel, levels, selectedId, error, onChange}: LevelFieldProps) => (
    <FormControl component="fieldset" fullWidth error={error}>
        <InputLabel>Niveau</InputLabel>
        <Select
            value={selectedId ?? emptyValue}
            onChange={event => {
                const levelId = event.target.value as string;
                onChange(levelId === emptyValue ? undefined : levelId);
            }}
        >
            {emptyLabel ? (
                <MenuItem value={emptyValue}>
                    <em>{emptyLabel}</em>
                </MenuItem>
            ) : undefined}
            {levels.map((level, index) => (
                <MenuItem key={index} value={level.id}>
                    {levelText(level)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
