import React from "react";
import dayjs from "dayjs";
import {Typography, TypographyVariant} from "@material-ui/core";
import {Conference, dateFormat} from "../Conference";

type ConferenceTextProps = {
    variant?: TypographyVariant;
    conference: Conference;
};

export const ConferenceText = ({conference, variant}: ConferenceTextProps) => (
    <Typography variant={variant}>
        {conference.title} - {dayjs(conference.date, dateFormat).format("L")}
    </Typography>
);
