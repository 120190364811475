import React from "react";
import {List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {Format} from "../Format";
import {UpdateButton} from "../../app/components/Button";

type FormatListProps = {
    readonly?: boolean;
    formats: Format[];
    onUpdate: (format: Format) => void;
};

export const FormatList = ({readonly, formats, onUpdate}: FormatListProps) => (
    <List>
        {formats.length === 0 ? (
            <ListItem>
                <ListItemText primary="Aucun format n'est défini." />
            </ListItem>
        ) : (
            formats.map(format => (
                <ListItem key={format.id}>
                    <ListItemText primary={format.label} secondary={format.description} />
                    {readonly ? undefined : (
                        <ListItemSecondaryAction>
                            <UpdateButton iconOnly onClick={() => onUpdate(format)} />
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            ))
        )}
    </List>
);
