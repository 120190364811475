import React from "react";
import {Tab, Tabs} from "@material-ui/core";
import {ConferenceView} from "./ConferenceView";

export type ConferenceTabsProps = {
    view: ConferenceView;
    setView: (view: ConferenceView) => void;
};

export const ConferenceTabs = ({view, setView}: ConferenceTabsProps) => (
    <Tabs
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
        value={view}
        onChange={(event, value) => setView(value)}
    >
        <Tab label="Résumé" value="summary" />
        <Tab label="Slots" value="selection" />
        <Tab label="Planification" value="planning" />
    </Tabs>
);
