const INITIAL = "INITIAL";
const PENDING = "PENDING";
const RESOLVED = "RESOLVED";
const REJECTED = "REJECTED";

export type Initial = {
    status: typeof INITIAL;
};

export type Pending = {
    status: typeof PENDING;
};

export type Resolved<T> = {
    status: typeof RESOLVED;
    result: T;
};

export type Rejected = {
    status: typeof REJECTED;
    code: string;
    message: string;
};

export type State<T> = Initial | Pending | Resolved<T> | Rejected;

export const initial = (): Initial => ({status: INITIAL});

export const pending = (): Pending => ({status: PENDING});

export const resolved = <T>(result: T): Resolved<T> => ({status: RESOLVED, result});

export const rejected = (code: string, message: string): Rejected => ({status: REJECTED, code, message});

export const isInitial = <T>(state: State<T>): state is Initial => state.status === INITIAL;

export const isPending = <T>(state: State<T>): state is Pending => state.status === PENDING;

export const isInitialOrPending = <T>(state: State<T>): state is Pending | Initial =>
    isInitial(state) || isPending(state);

export const isResolved = <T>(state: State<T>): state is Resolved<T> => state.status === RESOLVED;

export const isRejected = <T>(state: State<T>): state is Rejected => state.status === REJECTED;

const reasonHasProperty = <Y extends PropertyKey>(reason: unknown, prop: Y): reason is Record<Y, unknown> =>
    typeof reason === "object" && reason !== null && reason.hasOwnProperty(prop);

const reasonIsError = (reason: unknown): reason is Omit<Rejected, "status"> =>
    reasonHasProperty(reason, "code") &&
    reasonHasProperty(reason, "message") &&
    typeof reason.code === "string" &&
    typeof reason.message === "string";

export const errorFromReason = (reason: unknown | Omit<Rejected, "status">): Rejected => {
    if (reasonIsError(reason)) {
        return rejected(reason.code, reason.message);
    }
    return rejected("UNKNOWN_ERROR", "An unknown error has occurred");
};
