import {Level} from "./Level";
import firebase from "firebase";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const levelsCollection = firestore.collection("levels").withConverter(firestoreDataConverter<Level>());

export const observeLevels = () => (
    setSuccess: (levels: Level[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    levelsCollection
        .orderBy("value")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveLevel = async (level: Level) => {
    await levelsCollection.doc(level.id).set(level);
    return level;
};
