import React from "react";
import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    items: {
        "display": "inline-flex",
        "flexWrap": "wrap",
        "& > *": {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        },
        "& > :last-child": {
            marginRight: 0,
        },
    },
}));

type ItemGroupProps = {
    children: React.ReactNode[];
};

export const ItemGroup = ({children}: ItemGroupProps) => {
    const classes = useStyles();
    return <div className={classes.items}>{children}</div>;
};
