import {UserGroup} from "./UserGroup";
import firebase from "firebase";
import {firestoreDataConverter, firestore} from "../app/Firebase";

export const userGroupsCollection = firestore
    .collection("userGroups")
    .withConverter(firestoreDataConverter<UserGroup>());

export const observeUserGroups = () => (
    setSuccess: (levels: UserGroup[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) => userGroupsCollection.onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const findUserGroup = async (userGroupId: UserGroup["id"]) => {
    const snapshot = await userGroupsCollection.doc(userGroupId).get();
    return snapshot.data();
};
