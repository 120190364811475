import React from "react";
import dayjs from "dayjs";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from "@material-ui/core";
import {TimePicker} from "@material-ui/pickers";
import {ConferencePlanningTrackEditor} from "./ConferencePlanningTrackEditor";
import {Talk} from "../../talks/Talk";
import {CreateButton, DeleteButton} from "../../app/components/Button";
import {
    ConferencePlanning,
    ConferencePlanningGroup,
    ConferencePlanningTrack,
    pauseDurations,
} from "../ConferencePlanning";
import {maxItems} from "../ConferencePlanning.reducer";
import {timeFormat} from "../Conference";
import {Filler} from "../../app/components/Filler";
import {format} from "../../durations/Duration";

const useStyles = makeStyles((theme: Theme) => ({
    tracks: {
        minHeight: `${theme.spacing(10)}px`,
    },
}));

type ConferencePlanningGroupEditorProps = {
    readonly: boolean;
    disabled: boolean;
    talks: Talk[];
    groupId: string;
    planning: ConferencePlanning;
    onUpdateGroup: (groupId: string, groupUpdates: Partial<ConferencePlanningGroup>) => void;
    onDeleteGroup: (groupId: string) => void;
    onCreateTrack: (groupId: string) => void;
    onUpdateTrack: (trackId: string, trackUpdate: Partial<ConferencePlanningTrack>) => void;
    onDeleteTrack: (groupId: string, trackId: string) => void;
};

export const ConferencePlanningGroupEditor = ({
    readonly,
    disabled,
    talks,
    groupId,
    planning,
    onUpdateGroup,
    onDeleteGroup,
    onCreateTrack,
    onUpdateTrack,
    onDeleteTrack,
}: ConferencePlanningGroupEditorProps) => {
    const classes = useStyles();
    const groups = planning.roots.root.groupIds;
    const group = planning.groups[groupId];
    const trackIds = group.trackIds;
    return (
        <Card elevation={2}>
            {readonly ? undefined : (
                <CardActions>
                    <TimePicker
                        disabled={disabled}
                        ampm={false}
                        minutesStep={5}
                        label="Heure de début"
                        placeholder="Heure de début"
                        format={timeFormat}
                        value={dayjs(group.startTime, timeFormat)}
                        onChange={date => onUpdateGroup(groupId, {startTime: (date as dayjs.Dayjs).format(timeFormat)})}
                    />
                    <FormControl component="fieldset">
                        <InputLabel shrink>Pause</InputLabel>
                        <Select
                            disabled={disabled}
                            value={group.pauseDuration}
                            onChange={event => onUpdateGroup(groupId, {pauseDuration: event.target.value as number})}
                        >
                            {pauseDurations.map(pauseDuration => (
                                <MenuItem key={pauseDuration} value={pauseDuration}>
                                    {format(pauseDuration)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Filler />
                    <DeleteButton disabled={disabled || groups.length <= 1} onClick={() => onDeleteGroup(groupId)} />
                </CardActions>
            )}
            <CardContent>
                <Droppable
                    isDropDisabled={readonly || disabled}
                    droppableId={groupId}
                    type="GROUP"
                    direction="horizontal"
                >
                    {droppableTrack => (
                        <Grid container spacing={1} ref={droppableTrack.innerRef} className={classes.tracks}>
                            {trackIds.map((trackId, index) => (
                                <Draggable
                                    key={trackId}
                                    index={index}
                                    draggableId={trackId}
                                    isDragDisabled={readonly || disabled || trackIds.length <= 1}
                                >
                                    {draggableTrack => (
                                        <Grid
                                            item
                                            lg={3}
                                            sm={6}
                                            xs={12}
                                            ref={draggableTrack.innerRef}
                                            {...draggableTrack.draggableProps}
                                            {...draggableTrack.dragHandleProps}
                                        >
                                            <ConferencePlanningTrackEditor
                                                readonly={readonly}
                                                disabled={disabled}
                                                talks={talks}
                                                groupId={groupId}
                                                trackId={trackId}
                                                planning={planning}
                                                onUpdateTrack={onUpdateTrack}
                                                onDeleteTrack={trackIds.length > 1 ? onDeleteTrack : undefined}
                                            />
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {droppableTrack.placeholder}
                            {readonly || trackIds.length >= maxItems.tracks ? undefined : (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <CreateButton disabled={disabled} onClick={() => onCreateTrack(groupId)} />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Droppable>
            </CardContent>
        </Card>
    );
};
