import firebase from "firebase";
import {Format} from "./Format";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const formatsCollection = firestore.collection("formats").withConverter(firestoreDataConverter<Format>());

export const observeFormats = () => (
    setSuccess: (formats: Format[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    formatsCollection
        .orderBy("label")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveFormat = async (format: Format) => {
    await formatsCollection.doc(format.id).set(format);
    return format;
};
