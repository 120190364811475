import React, {useState} from "react";
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Toolbar,
    Typography,
} from "@material-ui/core";
import {documentsById} from "../../app/data/Document";
import {TalkText} from "../../talks/components/TalkText";
import {Talk} from "../../talks/Talk";
import {TopicText} from "../../topics/components/TopicText";
import {Submission} from "../../submissions/Submission";
import {SubmissionConfirmationIcon} from "../../submissions/components/SubmissionConfirmationIcon";
import {SubmissionSelectionIcon} from "../../submissions/components/SubmissionSelectionIcon";
import {TalkPreview} from "../../talks/components/TalkPreview";
import {applyTalkFilters, newTalkFilters, TalkFilters} from "../../talks/TalkFilters";
import {TalkIcon} from "../../talks/components/TalkIcon";
import {PreviewButton, SaveButton} from "../../app/components/Button";
import {Conference} from "../Conference";
import {selectTalks} from "../ConferencePlanning";
import {isPending, State} from "../../app/data/State";
import {ConferenceStats} from "./ConferenceStats";
import {Page} from "../../app/components/Page";

type ConferenceSubmissionListProps = {
    readonly: boolean;
    conference: Conference;
    submissions: Submission[];
    talks: Talk[];
    saveState: State<Conference>;
    onSave: (conference: Conference) => void;
};

export const ConferenceSelectionEditor = ({
    readonly,
    conference,
    submissions,
    talks,
    saveState,
    onSave,
}: ConferenceSubmissionListProps) => {
    const [filters, setFilters] = useState<TalkFilters>(newTalkFilters());
    const [previewTalk, setPreviewTalk] = useState<Talk | undefined>(undefined);

    const initialSelection = conference.selection;
    const [selection, setSelection] = useState(initialSelection);
    const onSaveSelection = (selection: Conference["selection"]) => {
        onSave(selectTalks(conference, selection));
    };
    const isSelectionEdited = JSON.stringify([...initialSelection].sort()) !== JSON.stringify([...selection].sort());

    const talksById = documentsById(talks);
    const isTalkDisplayed = (talk?: Talk) => !talk || applyTalkFilters(talk, filters);
    const onSelect = (talkId: string) => {
        setSelection(
            selection.includes(talkId) ? selection.filter(selectedId => selectedId !== talkId) : [...selection, talkId],
        );
    };

    const isLoading = isPending(saveState);

    return (
        <Page
            toolbar={
                <>
                    <Toolbar variant="dense">
                        <SaveButton
                            onClick={() => onSaveSelection(selection)}
                            disabled={readonly || !isSelectionEdited}
                            loading={isLoading}
                        />
                    </Toolbar>
                    <Divider />
                    <ConferenceStats
                        talks={talks}
                        selection={conference.selection}
                        filters={filters}
                        onChangeFilters={setFilters}
                    />
                </>
            }
        >
            <List>
                {submissions.length === 0 ? (
                    <ListItem>
                        <ListItemText primary="Aucun orateur n'est encore enregistré." />
                    </ListItem>
                ) : (
                    submissions.map(submission => {
                        const talk = talksById.get(submission.talkId);
                        const isSelected = selection.includes(submission.talkId);
                        return isTalkDisplayed(talk) ? (
                            <ListItem
                                key={submission.talkId}
                                disabled={isLoading}
                                button
                                selected={isSelected}
                                onClick={() => !readonly && onSelect(submission.talkId)}
                            >
                                <ListItemIcon>
                                    <SubmissionConfirmationIcon submission={submission} />
                                </ListItemIcon>
                                <ListItemIcon>
                                    <SubmissionSelectionIcon selected={isSelected} />
                                </ListItemIcon>
                                {talk ? (
                                    <ListItemText>
                                        <TalkText speakers talk={talk} />
                                        <TopicText topicId={talk.topicId} />
                                    </ListItemText>
                                ) : (
                                    <ListItemText>
                                        <Typography color="error">
                                            {`Le slot ${submission.talkId} n'existe pas ou a été supprimé.`}
                                        </Typography>
                                    </ListItemText>
                                )}
                                <ListItemSecondaryAction>
                                    <PreviewButton
                                        disabled={!talk || isLoading}
                                        iconOnly
                                        endIcon={<TalkIcon />}
                                        onClick={() => talk && setPreviewTalk(talk)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ) : undefined;
                    })
                )}
                {previewTalk ? <TalkPreview talk={previewTalk} onClose={() => setPreviewTalk(undefined)} /> : undefined}
            </List>
        </Page>
    );
};
