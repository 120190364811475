import {User} from "../users/User";

export type UserGroup = {
    id: string;
    userIds: string[];
};

export const organizersUserGroupId = "organizers";

export const isInGroup = (user: User, organizersUserGroup: UserGroup | undefined) =>
    organizersUserGroup?.userIds.includes(user.id) ?? false;

export const findOrganizers = (users: User[], userGroups: UserGroup[]) => {
    const organizerIds = userGroups
        .filter(userGroup => userGroup.id === organizersUserGroupId)
        .flatMap(userGroup => userGroup.userIds);
    return users.filter(user => organizerIds.includes(user.id));
};
