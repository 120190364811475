import dayjs from "dayjs";
import {dateFormat} from "./Conference";

export type ConferenceFilters = {
    afterDate: string;
    beforeDate: string;
    limit: number;
};

const today = dayjs().startOf("day");

export const newConferenceListFilters = (): ConferenceFilters => ({
    afterDate: today.add(-1, "month").format(dateFormat),
    beforeDate: today.add(6, "month").format(dateFormat),
    limit: 20,
});

export const newConferencesToSubmitFilters = (): ConferenceFilters => ({
    afterDate: today.add(0, "month").format(dateFormat),
    beforeDate: today.add(6, "month").format(dateFormat),
    limit: 20,
});
