import React, {useState} from "react";
import {FormControlLabel, Grid, Switch, TextField, Tooltip, Typography} from "@material-ui/core";
import {Dialog} from "../../app/components/Dialog";
import {newTalk, Talk, talkErrors} from "../Talk";
import {DurationField} from "../../durations/components/DurationField";
import {FormatField} from "../../formats/components/FormatField";
import {SaveButton} from "../../app/components/Button";
import {UserListField} from "../../users/components/UserListField";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {TopicField} from "../../topics/components/TopicField";
import {LevelField} from "../../levels/components/LevelField";
import {useOptions} from "../../app/Options";

type TalkFormProps = {
    talk?: Talk;
    saveState: State<Talk>;
    onSave: (talk: Talk) => void;
    onClose: () => void;
};

export const TalkForm = ({talk: existingTalk, saveState, onClose, onSave}: TalkFormProps) => {
    const {authenticatedUser, durations, formats, levels, topics, users} = useOptions();
    const [talk, setTalk] = useState<Talk>(existingTalk ?? newTalk(authenticatedUser));

    const errors = talkErrors(talk, authenticatedUser);
    const hasError =
        errors.title ||
        errors.formatId ||
        errors.durationId ||
        errors.topicId ||
        errors.levelId ||
        errors.pitch ||
        errors.speakerIds;

    const onEdit = (updates: Partial<Talk>) => setTalk({...talk, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingTalk ? "Éditer le slot" : "Créer un slot"}
            actions={<SaveButton onClick={() => onSave(talk)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Titre"
                        placeholder="Titre du slot"
                        value={talk.title}
                        error={errors.title}
                        onChange={event => onEdit({title: event.target.value})}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormatField
                        formats={formats}
                        selectedId={talk.formatId}
                        error={errors.formatId}
                        onChange={formatId => onEdit({formatId})}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DurationField
                        durations={durations}
                        selectedId={talk.durationId}
                        error={errors.durationId}
                        onChange={durationId => onEdit({durationId})}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TopicField
                        topics={topics}
                        selectedId={talk.topicId}
                        error={errors.topicId}
                        onChange={topicId => onEdit({topicId})}
                    />
                </Grid>
                <Grid item xs={6} md={8}>
                    <LevelField
                        levels={levels}
                        selectedId={talk.levelId}
                        error={errors.levelId}
                        onChange={levelId => onEdit({levelId})}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <Tooltip title={<Typography>Une plénière requiert la présence de toutes et tous</Typography>}>
                        <FormControlLabel
                            labelPlacement="top"
                            control={
                                <Switch
                                    color="primary"
                                    checked={talk.plenary}
                                    onChange={(event, plenary) => onEdit({plenary})}
                                />
                            }
                            label="Plénière"
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        label="Description"
                        placeholder="Cette slot portera sur..."
                        rows={7}
                        value={talk.pitch}
                        error={errors.pitch}
                        onChange={event => onEdit({pitch: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        label="Remarque pour les organisateurs"
                        placeholder="Orateurs externes, matériel spécifique, contraintes, ..."
                        rows={2}
                        value={talk.info}
                        onChange={event => onEdit({info: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UserListField
                        label="Orateurs"
                        placeholder="Orateurs"
                        users={users}
                        required={authenticatedUser}
                        valueIds={talk.speakerIds}
                        error={errors.speakerIds}
                        onChange={speakerIds => onEdit({speakerIds})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
