import firebase from "firebase";
import {Submission} from "./Submission";
import {Conference} from "../conferences/Conference";
import {Talk} from "../talks/Talk";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const submissionsCollection = firestore
    .collection("submissions")
    .withConverter(firestoreDataConverter<Submission>());

export const observeSubmissionsForConference = (conferenceId: Conference["id"]) => (
    setSuccess: (levels: Submission[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    submissionsCollection
        .where("conferenceId", "==", conferenceId)
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const observeSubmissionsForTalk = (talkId: Talk["id"]) => (
    setSuccess: (levels: Submission[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    submissionsCollection
        .where("talkId", "==", talkId)
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveSubmission = async (submission: Submission) => {
    await submissionsCollection.doc(submission.id).set(submission);
    return submission;
};

export const deleteSubmission = async (submission: Submission) => {
    await submissionsCollection.doc(submission.id).delete();
    return submission;
};
