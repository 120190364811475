import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export const Filler = () => {
    const classes = useStyles();
    return <span className={classes.root} />;
};
