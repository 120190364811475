import React from "react";
import {Typography, TypographyVariant} from "@material-ui/core";
import {Room} from "../Room";

type RoomTextProps = {
    variant?: TypographyVariant;
    room: Room;
};

export const RoomText = ({room, variant}: RoomTextProps) => <Typography variant={variant}>{room.name}</Typography>;
