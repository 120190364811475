import {newId} from "../app/data/Document";
import dayjs from "dayjs";

export type Duration = {
    id: string;
    value: number;
};

export const newDuration = (): Duration => ({
    id: newId(),
    value: 0,
});

export type DurationErrors = {
    value: boolean;
};

export const durationErrors = (duration: Duration): DurationErrors => ({
    value: duration.value <= 0,
});

export const format = (minutes: number) => {
    const duration = dayjs.duration(minutes, "minutes");
    const formatHour = duration.hours();
    const formatMinutes = duration.minutes();
    const minutesSuffixFr = `${formatMinutes > 1 ? "minutes" : "minute"}`;
    return formatHour > 0
        ? duration.format(`H [${formatHour != 1 ? "heures" : "heure"} et] m [${minutesSuffixFr}]`)
        : duration.format(`m [${minutesSuffixFr}]`);
};
