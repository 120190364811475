import React from "react";
import {Card, CardContent, CardHeader, Divider, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: theme.palette.common.white,
    },
    header: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        flexGrow: 1,
        overflow: "scroll",
        padding: theme.spacing(2),
    },
}));

type PageProps = {
    title?: React.ReactNode;
    tabs?: React.ReactNode;
    toolbar?: React.ReactNode;
    children: React.ReactNode;
    actions?: React.ReactNode;
};

export const Page = ({title, toolbar, children}: PageProps) => {
    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={0}>
            {title ? <CardHeader title={title} className={classes.header} /> : undefined}
            {toolbar ? (
                <>
                    {toolbar}
                    <Divider />
                </>
            ) : undefined}
            <CardContent className={classes.content}>{children}</CardContent>
        </Card>
    );
};
