import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Authentication} from "./Authentication";

export type AuthenticationSliceState = {
    authentication?: Authentication;
};

const initialState: AuthenticationSliceState = {};

export const {reducer: authenticationReducer, actions: authenticationActions} = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthentication: (state, action: PayloadAction<Authentication>) => {
            state.authentication = action.payload;
        },
    },
});
