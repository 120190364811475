import React, {useCallback, useEffect, useState} from "react";
import {pending, resolved, errorFromReason, State, initial} from "./State";

export const useObserverState = <T>(
    observer: (setSuccess: (result: T) => void, setError: (reason: unknown) => void) => (() => void | undefined) | void,
    dependencies: React.DependencyList,
): State<T> => {
    const [state, setState] = useState<State<T>>(initial());
    useEffect(() => {
        setState(pending());
        return observer(
            result => setState(resolved(result)),
            reason => setState(errorFromReason(reason)),
        );
    }, dependencies);
    return state;
};

export const useCallbackState = <A, T>(
    callback: (arg: A) => Promise<T>,
    dependencies: React.DependencyList,
): [State<T>, (arg: A) => void] => {
    const [state, setState] = useState<State<T>>(initial());
    const action = useCallback((arg: A) => {
        setState(pending());
        callback(arg)
            .then(result => setState(resolved(result)))
            .catch(reason => setState(errorFromReason(reason)));
    }, dependencies);
    return [state, action];
};
