import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import {firebaseOptions} from "./Firebase.options";

export const app = firebase.initializeApp(firebaseOptions, "ked-app");

export const auth = app.auth();

export const firestore = app.firestore();
export const firestoreDataConverter = <
    T extends Record<string, unknown> = firebase.firestore.DocumentData
>(): firebase.firestore.FirestoreDataConverter<T> => ({
    fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot<T>): T => {
        const document = snapshot.data();
        return {id: snapshot.id, ...document};
    },
    toFirestore: (item: T): firebase.firestore.DocumentData => {
        const timestamp = firebase.firestore.Timestamp.now().toMillis();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {id, ...document} = item;
        return {...document, createdAt: item.createdAt ?? timestamp, updatedAt: timestamp};
    },
});

export const functions = app.functions(firebaseOptions.region);
