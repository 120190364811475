import React from "react";
import {List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme} from "@material-ui/core";
import {Topic} from "../Topic";
import {UpdateButton} from "../../app/components/Button";

const useStyles = makeStyles((theme: Theme) => ({
    topic: {
        borderStyle: "solid",
        borderWidth: `0 0 0 ${theme.spacing(1)}px`,
        paddingLeft: theme.spacing(1),
    },
}));

type TopicListProps = {
    readonly?: boolean;
    topics: Topic[];
    onUpdate: (topic: Topic) => void;
};

export const TopicList = ({readonly, topics, onUpdate}: TopicListProps) => {
    const classes = useStyles();
    return (
        <List>
            {topics.length === 0 ? (
                <ListItem>
                    <ListItemText primary="Aucun thème n'est défini." />
                </ListItem>
            ) : (
                topics.map(topic => (
                    <ListItem key={topic.id}>
                        <ListItemText
                            className={classes.topic}
                            style={{borderColor: topic.color}}
                            primary={topic.label}
                        />
                        {readonly ? undefined : (
                            <ListItemSecondaryAction>
                                <UpdateButton iconOnly onClick={() => onUpdate(topic)} />
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))
            )}
        </List>
    );
};
