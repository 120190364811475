import React from "react";
import {useTheme} from "@material-ui/core";
import {ReactComponent as KedLogoSvg} from "./KedLogo.svg";

export type KedLogoProps = {
    size?: "small" | "medium" | "large";
    color?: "primary" | "text";
};

export const KedLogo = ({size = "medium", color = "primary"}: KedLogoProps) => {
    const theme = useTheme();
    return (
        <KedLogoSvg
            fill={color === "primary" ? theme.palette.primary.main : theme.palette.primary.contrastText}
            height={size === "small" ? 30 : size === "medium" ? 60 : 90}
        />
    );
};
