import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {Grid, Toolbar} from "@material-ui/core";
import {ConferencePlanningGroupEditor} from "./ConferencePlanningGroupEditor";
import {
    ConferencePlanning,
    ConferencePlanningGroup,
    ConferencePlanningTrack,
    deleteGroup,
    deleteTrack,
    insertGroup,
    insertTrack,
    updateGroup,
    updateTrack,
} from "../ConferencePlanning";
import {Talk} from "../../talks/Talk";
import {CreateButton, PublishButton, SaveButton} from "../../app/components/Button";
import {conferencePlanningReducer, maxItems} from "../ConferencePlanning.reducer";
import {Conference} from "../Conference";
import {isPending, State} from "../../app/data/State";
import {Page} from "../../app/components/Page";

export type ConferencePlanningEditorProps = {
    readonly: boolean;
    conference: Conference;
    talks: Talk[];
    saveState: State<Conference>;
    onSave: (conference: Conference) => void;
    onPublish: () => void;
};

export const ConferencePlanningEditor = ({
    readonly,
    conference,
    talks,
    saveState,
    onSave,
    onPublish,
}: ConferencePlanningEditorProps) => {
    const initialPlanning = conference.planning;
    const [planning, setPlanning] = useState(initialPlanning);
    const onSavePlanning = (planning: ConferencePlanning) => onSave({...conference, planning});
    const isPlanningEdited = JSON.stringify(initialPlanning) !== JSON.stringify(planning);

    const onCreateGroup = () => setPlanning(insertGroup(planning));
    const onUpdateGroup = (groupId: string, groupUpdate: Partial<ConferencePlanningGroup>) =>
        setPlanning(updateGroup(planning, groupId, groupUpdate));
    const onDeleteGroup = (groupId: string) => setPlanning(deleteGroup(planning, groupId));

    const onCreateTrack = (groupId: string) => setPlanning(insertTrack(planning, groupId));
    const onUpdateTrack = (trackId: string, trackUpdate: Partial<ConferencePlanningTrack>) =>
        setPlanning(updateTrack(planning, trackId, trackUpdate));
    const onDeleteTrack = (groupId: string, trackId: string) => setPlanning(deleteTrack(planning, groupId, trackId));

    const onDragEnd = (dropResult: DropResult) => setPlanning(conferencePlanningReducer(planning, dropResult));

    const isLoading = isPending(saveState);

    const {groupIds} = planning.roots.root;

    return (
        <Page
            toolbar={
                <Toolbar variant="dense">
                    <SaveButton
                        onClick={() => onSavePlanning(planning)}
                        disabled={readonly || !isPlanningEdited}
                        loading={isLoading}
                    />
                    <PublishButton onClick={onPublish} disabled={readonly || isPlanningEdited} loading={isLoading} />
                </Toolbar>
            }
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable isDropDisabled={readonly || isLoading} droppableId="root" type="ROOT" direction="vertical">
                    {droppableGroup => (
                        <Grid container spacing={2} ref={droppableGroup.innerRef}>
                            {groupIds.map((groupId, index) => (
                                <Draggable
                                    isDragDisabled={readonly || isLoading}
                                    key={groupId}
                                    index={index}
                                    draggableId={groupId}
                                >
                                    {draggableGroup => (
                                        <Grid
                                            item
                                            xs={12}
                                            ref={draggableGroup.innerRef}
                                            {...draggableGroup.draggableProps}
                                            {...draggableGroup.dragHandleProps}
                                        >
                                            <ConferencePlanningGroupEditor
                                                readonly={readonly}
                                                disabled={isLoading}
                                                talks={talks}
                                                groupId={groupId}
                                                planning={planning}
                                                onUpdateGroup={onUpdateGroup}
                                                onDeleteGroup={onDeleteGroup}
                                                onCreateTrack={onCreateTrack}
                                                onUpdateTrack={onUpdateTrack}
                                                onDeleteTrack={onDeleteTrack}
                                            />
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {droppableGroup.placeholder}
                            {!readonly && groupIds.length < maxItems.groups ? (
                                <Grid item xs={12}>
                                    <CreateButton
                                        disabled={isLoading || groupIds.length >= maxItems.groups}
                                        onClick={onCreateGroup}
                                    />
                                </Grid>
                            ) : undefined}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Page>
    );
};
