import React, {ReactNode} from "react";
import {Talk} from "../Talk";
import {Grid, makeStyles, Typography} from "@material-ui/core";
import {TopicText} from "../../topics/components/TopicText";
import {LevelText} from "../../levels/components/LevelText";
import {UserAvatarList} from "../../users/components/UserAvatarList";

const useStyles = makeStyles({
    text: {
        whiteSpace: "pre-line",
    },
});

type TalkInfoProps = {
    talk: Talk;
    submissionsSection?: ReactNode;
};

export const TalkDetail = ({submissionsSection, talk}: TalkInfoProps) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Description</Typography>
                <Typography variant="body1" className={classes.text}>
                    {talk.pitch}
                </Typography>
            </Grid>
            {talk.info ? (
                <Grid item xs={12}>
                    <Typography variant="h6">Remarque pour les organisateurs</Typography>
                    <Typography variant="body1" className={classes.text}>
                        {talk.info}
                    </Typography>
                </Grid>
            ) : undefined}
            <Grid item xs={12} md={4}>
                <Typography variant="h6">Thème</Typography>
                <TopicText topicId={talk.topicId} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6">Niveau</Typography>
                <LevelText levelId={talk.levelId} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h6">Orateurs</Typography>
                <UserAvatarList userIds={talk.speakerIds} />
            </Grid>
            {submissionsSection ? (
                <Grid item xs={12}>
                    <Typography variant="h6">Propositions</Typography>
                    {submissionsSection}
                </Grid>
            ) : undefined}
        </Grid>
    );
};
