import React, {useEffect, useState} from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {Page} from "../../app/components/Page";
import {useAuthentication} from "../../auth/Authentication.provider";
import {DurationList} from "../components/DurationList";
import {useDurations, useDurationSaveAction} from "../Duration.hooks";
import {Loadable} from "../../app/components/Loadable";
import {CreateButton} from "../../app/components/Button";
import {DurationForm} from "../components/DurationForm";
import {Duration} from "../Duration";
import {isResolved} from "../../app/data/State";

export const DurationListPage = () => {
    const authentication = useAuthentication();
    const durationsState = useDurations();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [updateFormDuration, setUpdateFormDuration] = useState<Duration | undefined>(undefined);
    const [saveState, saveAction] = useDurationSaveAction();

    useEffect(() => {
        if (isResolved(saveState)) {
            setCreateFormIsOpen(false);
            setUpdateFormDuration(undefined);
        }
    }, [saveState]);

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Durées</Typography>}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    </Toolbar>
                )
            }
        >
            <Loadable state={durationsState}>
                {durations => (
                    <DurationList readonly={readonly} durations={durations} onUpdate={setUpdateFormDuration} />
                )}
            </Loadable>
            {createFormIsOpen ? (
                <DurationForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {updateFormDuration ? (
                <DurationForm
                    duration={updateFormDuration}
                    saveState={saveState}
                    onSave={saveAction}
                    onClose={() => setUpdateFormDuration(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
