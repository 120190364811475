import {Talk} from "../talks/Talk";
import {Conference} from "../conferences/Conference";

export type Submission = {
    id: string;
    conferenceId: string;
    talkId: string;
    confirmed: boolean;
};

export const newSubmission = ({id: conferenceId}: Conference, {id: talkId}: Talk, confirmed: boolean) => ({
    id: `${conferenceId}:${talkId}`,
    conferenceId,
    talkId,
    confirmed,
});
