import {Duration} from "./Duration";
import firebase from "firebase";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const durationsCollection = firestore.collection("durations").withConverter(firestoreDataConverter<Duration>());

export const observeDurations = () => (
    setSuccess: (durations: Duration[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    durationsCollection
        .orderBy("value")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveDuration = async (duration: Duration) => {
    await durationsCollection.doc(duration.id).set(duration);
    return duration;
};
