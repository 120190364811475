import firebase from "firebase";
import {Conference} from "./Conference";
import {ConferenceFilters} from "./ConferenceFilters";
import {firestore, firestoreDataConverter} from "../app/Firebase";
import {submissionsCollection} from "../submissions/Submission.repository";

export const conferencesCollection = firestore
    .collection("conferences")
    .withConverter(firestoreDataConverter<Conference>());

export const observeConferences = ({afterDate, beforeDate}: ConferenceFilters) => (
    setSuccess: (conferences: Conference[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    conferencesCollection
        .where("date", ">=", afterDate)
        .where("date", "<=", beforeDate)
        .orderBy("date", "asc")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const observeConference = (conferenceId: Conference["id"]) => (
    setSuccess: (conference?: Conference) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) => conferencesCollection.doc(conferenceId).onSnapshot(snapshot => setSuccess(snapshot.data()), setFailure);

export const findConferences = async (conferenceIds: Conference["id"][]) => {
    const snapshots = await Promise.all(
        conferenceIds.map(conferenceId => conferencesCollection.doc(conferenceId).get()),
    );
    return snapshots
        .map(talk => talk.data())
        .filter((conference): conference is Conference => conference !== undefined);
};

export const saveConference = async (conference: Conference) => {
    await conferencesCollection.doc(conference.id).set(conference);
    return conference;
};

export const deleteConference = async (conference: Conference) => {
    const submissionsQuery = await submissionsCollection.where("conferenceId", "==", conference.id).get();
    const batch = firestore.batch();
    batch.delete(conferencesCollection.doc(conference.id));
    submissionsQuery.docs.forEach(({ref}) => batch.delete(ref));
    await batch.commit();
    return conference;
};
