import React from "react";
import {List, ListItem, ListItemIcon} from "@material-ui/core";
import {DeleteButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {Conference} from "../Conference";
import {ConferenceIcon} from "./ConferenceIcon";
import {ConferenceText} from "./ConferenceText";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";

type ConferenceDeleteProps = {
    conference: Conference;
    deleteState: State<Conference>;
    onClose: () => void;
    onDelete: (conference: Conference) => void;
};

export const ConferenceDelete = ({conference, deleteState, onClose, onDelete}: ConferenceDeleteProps) => {
    const isLoading = isPending(deleteState);
    return (
        <Dialog
            title="Supprimer la conference"
            actions={<DeleteButton onClick={() => onDelete(conference)} loading={isLoading} />}
            onClose={onClose}
        >
            <List>
                <ListItem>
                    <ListItemIcon>
                        <ConferenceIcon color="primary" />
                    </ListItemIcon>
                    <ConferenceText conference={conference} />
                </ListItem>
            </List>
            {isRejected(deleteState) ? <ErrorNotification error={deleteState} /> : undefined}
        </Dialog>
    );
};
