import React from "react";
import {
    Divider,
    Drawer,
    Hidden,
    Link,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuItem,
    MenuList,
} from "@material-ui/core";
import {OpenInNew as AgendaIcon} from "@material-ui/icons";
import {useRoutes} from "./Routes";
import {ConferenceIcon} from "./conferences/components/ConferenceIcon";
import {RoomIcon} from "./rooms/components/RoomIcon";
import {TalkIcon} from "./talks/components/TalkIcon";
import {FormatIcon} from "./formats/components/FormatIcon";
import {TopicIcon} from "./topics/components/TopicIcon";
import {DurationIcon} from "./durations/components/DurationIcon";
import {LevelIcon} from "./levels/components/LevelIcon";
import {UserIcon} from "./users/components/UserIcon";

export const menuWidth = "200px";

const useStyles = makeStyles(() => ({
    root: {
        minWidth: menuWidth,
    },
}));

type MenuContentProps = {
    onClick?: () => void;
};

export const MenuContent = ({onClick}: MenuContentProps) => {
    const classes = useStyles();
    const routes = useRoutes();

    const onMenuClick = (route: () => void) => {
        onClick && onClick();
        route();
    };

    return (
        <MenuList className={classes.root}>
            <MenuItem onClick={() => onMenuClick(routes.conferences)}>
                <ListItemIcon>
                    <ConferenceIcon />
                </ListItemIcon>
                <ListItemText>Conférences</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.talks)}>
                <ListItemIcon>
                    <TalkIcon />
                </ListItemIcon>
                <ListItemText>Slots</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.speakers)}>
                <ListItemIcon>
                    <UserIcon />
                </ListItemIcon>
                <ListItemText>Orateurs</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.rooms)}>
                <ListItemIcon>
                    <RoomIcon />
                </ListItemIcon>
                <ListItemText>Salles</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => onMenuClick(routes.formats)}>
                <ListItemIcon>
                    <FormatIcon />
                </ListItemIcon>
                <ListItemText>Formats</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.durations)}>
                <ListItemIcon>
                    <DurationIcon />
                </ListItemIcon>
                <ListItemText>Durées</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.topics)}>
                <ListItemIcon>
                    <TopicIcon />
                </ListItemIcon>
                <ListItemText>Thèmes</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick(routes.levels)}>
                <ListItemIcon>
                    <LevelIcon />
                </ListItemIcon>
                <ListItemText>Niveaux</ListItemText>
            </MenuItem>
            <Divider />
            <Link href="https://ked-agenda.publicissapient.fr" target="_blank noopener noreferer">
                <MenuItem>
                    <ListItemIcon>
                        <AgendaIcon />
                    </ListItemIcon>
                    <ListItemText>Agenda</ListItemText>
                </MenuItem>
            </Link>
        </MenuList>
    );
};

type MenuProps = {
    menuOpen: boolean;
    onMenuToggle: () => void;
};

export const Menu = (props: MenuProps) => (
    <>
        <Hidden smDown>
            <MenuContent />
        </Hidden>
        <Hidden mdUp>
            <Drawer keepMounted open={props.menuOpen} onClose={props.onMenuToggle}>
                <MenuContent onClick={props.onMenuToggle} />
            </Drawer>
        </Hidden>
    </>
);
