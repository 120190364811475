import firebase from "firebase";
import {User} from "../users/User";
import {saveUser} from "../users/User.repository";
import {findUserGroup} from "../userGroups/UserGroup.repository";
import {isInGroup, organizersUserGroupId} from "../userGroups/UserGroup";
import {Authentication} from "./Authentication";

export const convertAndUpdateFirebaseUser = async (firebaseUser: firebase.User): Promise<Authentication> => {
    const user: User = {
        id: firebaseUser.uid,
        email: firebaseUser.email ?? "",
        displayName: firebaseUser.displayName ?? firebaseUser.email?.split("@")[0] ?? "A user has no name",
        photoURL: firebaseUser.photoURL ?? "",
    };
    await saveUser(user);
    const organizersUserGroup = await findUserGroup(organizersUserGroupId);
    const isOrganizer = isInGroup(user, organizersUserGroup);
    return {user, isOrganizer};
};
