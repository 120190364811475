import React, {useMemo} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {Conference} from "./conferences/Conference";
import {ConferencePage} from "./conferences/pages/ConferencePage";
import {ConferenceListPage} from "./conferences/pages/ConferenceListPage";
import {ConferenceView} from "./conferences/components/ConferenceView";
import {RoomListPage} from "./rooms/pages/RoomListPage";
import {Talk} from "./talks/Talk";
import {TalkPage} from "./talks/pages/TalkPage";
import {TalkListPage} from "./talks/pages/TalkListPage";
import {FormatListPage} from "./formats/pages/FormatListPage";
import {DurationListPage} from "./durations/pages/DurationListPage";
import {TopicListPage} from "./topics/pages/TopicListPage";
import {LevelListPage} from "./levels/pages/LevelListPage";
import {UserListPage} from "./users/pages/UserListPage";

export const Routes = () => (
    <Switch>
        <Route exact path="/conferences">
            <ConferenceListPage />
        </Route>
        <Route exact path="/conferences/:conferenceId/:view(selection|planning)?">
            <ConferencePage />
        </Route>
        <Route exact path="/talks">
            <TalkListPage />
        </Route>
        <Route exact path="/talks/:talkId">
            <TalkPage />
        </Route>
        <Route exact path="/speakers">
            <UserListPage />
        </Route>
        <Route exact path="/rooms">
            <RoomListPage />
        </Route>
        <Route exact path="/formats">
            <FormatListPage />
        </Route>
        <Route exact path="/durations">
            <DurationListPage />
        </Route>
        <Route exact path="/topics">
            <TopicListPage />
        </Route>
        <Route exact path="/levels">
            <LevelListPage />
        </Route>
        <Route>
            <Redirect to="/talks" />
        </Route>
    </Switch>
);

export const useRoutes = () => {
    const history = useHistory();
    return useMemo(
        () => ({
            conferences: () => history.push("/conferences"),
            conference: (id: Conference["id"], view: ConferenceView = "summary") =>
                history.push(`/conferences/${id}${view === "summary" ? "" : `/${view}`}`),
            talks: () => history.push("/talks"),
            talk: (id: Talk["id"]) => history.push(`/talks/${id}`),
            rooms: () => history.push("/rooms"),
            formats: () => history.push("/formats"),
            durations: () => history.push("/durations"),
            topics: () => history.push("/topics"),
            levels: () => history.push("/levels"),
            speakers: () => history.push("/speakers"),
        }),
        [history],
    );
};
