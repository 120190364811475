import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {SaveButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {newTopic, Topic, topicErrors} from "../Topic";
import {ConfCompanionTopicField} from "./ConfCompanionTopicField";

type TopicFormProps = {
    topic?: Topic;
    saveState: State<Topic>;
    onSave: (topic: Topic) => void;
    onClose: () => void;
};

export const TopicForm = (props: TopicFormProps) => {
    const {topic: existingTopic, saveState, onClose, onSave} = props;

    const [topic, setTopic] = useState<Topic>(existingTopic ?? newTopic());

    const errors = topicErrors(topic);
    const hasError = errors.label || errors.color;

    const onEdit = (updates: Partial<Topic>) => setTopic({...topic, ...updates});

    const isLoading = isPending(saveState);

    return (
        <Dialog
            title={existingTopic ? "Éditer le thème" : "Créer un thème"}
            actions={<SaveButton onClick={() => onSave(topic)} disabled={hasError} loading={isLoading} />}
            onClose={onClose}
        >
            <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Libellé"
                        placeholder="Back, Front, Mobile, Agile, ..."
                        value={topic.label}
                        error={errors.label}
                        onChange={event => onEdit({label: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="color"
                        label="Couleur (hexadecimal)"
                        placeholder="#ff05f5"
                        value={topic.color}
                        error={errors.color}
                        onChange={event => onEdit({color: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ConfCompanionTopicField
                        value={topic.confCompanionCode}
                        onChange={value => onEdit({confCompanionCode: value})}
                    />
                </Grid>
            </Grid>
            {isRejected(saveState) ? <ErrorNotification error={saveState} /> : undefined}
        </Dialog>
    );
};
