import React, {useEffect, useState} from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {FormatList} from "../components/FormatList";
import {Page} from "../../app/components/Page";
import {useAuthentication} from "../../auth/Authentication.provider";
import {useFormats, useFormatSaveAction} from "../Format.hooks";
import {Loadable} from "../../app/components/Loadable";
import {CreateButton} from "../../app/components/Button";
import {FormatForm} from "../components/FormatForm";
import {Format} from "../Format";
import {isResolved} from "../../app/data/State";

export const FormatListPage = () => {
    const authentication = useAuthentication();
    const formatsState = useFormats();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [updateFormFormat, setUpdateFormFormat] = useState<Format | undefined>(undefined);
    const [saveState, saveAction] = useFormatSaveAction();

    useEffect(() => {
        if (isResolved(saveState)) {
            setCreateFormIsOpen(false);
            setUpdateFormFormat(undefined);
        }
    }, [saveState]);

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Formats</Typography>}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    </Toolbar>
                )
            }
        >
            <Loadable state={formatsState}>
                {formats => <FormatList readonly={readonly} formats={formats} onUpdate={setUpdateFormFormat} />}
            </Loadable>
            {createFormIsOpen ? (
                <FormatForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {updateFormFormat ? (
                <FormatForm
                    format={updateFormFormat}
                    saveState={saveState}
                    onSave={saveAction}
                    onClose={() => setUpdateFormFormat(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
