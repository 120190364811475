import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {confCompanionTopicCodes} from "../Topic";

type ConfCompanionTopicFieldProps = {
    value?: string;
    onChange: (topic: string) => void;
};

export const ConfCompanionTopicField = ({value, onChange}: ConfCompanionTopicFieldProps) => (
    <FormControl component="fieldset" fullWidth>
        <InputLabel>Code pour l&apos;application Conf Companion</InputLabel>
        <Select value={value} displayEmpty onChange={event => onChange(event.target.value as string)}>
            <MenuItem>-️</MenuItem>
            {confCompanionTopicCodes.map((confCompanionCode, index) => (
                <MenuItem key={index} value={confCompanionCode}>
                    {confCompanionCode}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
