import React, {useState} from "react";
import dayjs from "dayjs";
import {FilterList as FilterIcon} from "@material-ui/icons";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {Dialog} from "../../app/components/Dialog";
import {dateFormat} from "../Conference";
import {SearchButton} from "../../app/components/Button";
import {ConferenceFilters} from "../ConferenceFilters";

type ConferenceFiltersFormProps = {
    filters: ConferenceFilters;
    onApply: (filters: ConferenceFilters) => void;
    onClose: () => void;
};

export const ConferenceFiltersForm = ({filters: initialFilters, onApply, onClose}: ConferenceFiltersFormProps) => {
    const [filters, setFilters] = useState(initialFilters);

    const onUpdate = (updates: Partial<ConferenceFilters>) => setFilters({...filters, ...updates});

    const handleSearch = () => {
        onClose();
        onApply(filters);
    };

    return (
        <Dialog title="Filtrer les conférences" actions={<SearchButton onClick={handleSearch} />} onClose={onClose}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <DatePicker
                        fullWidth
                        id="conferences-search-after"
                        label="Après le"
                        placeholder="Après le"
                        format={dateFormat}
                        value={dayjs(filters.afterDate, dateFormat)}
                        onChange={date => onUpdate({afterDate: (date as dayjs.Dayjs).format(dateFormat)})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        fullWidth
                        id="conferences-search-before"
                        label="Avant le"
                        placeholder="Avant le"
                        format={dateFormat}
                        value={dayjs(filters.beforeDate, dateFormat)}
                        onChange={date => onUpdate({beforeDate: (date as dayjs.Dayjs).format(dateFormat)})}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export type ConferenceFiltersTooltipProps = {
    onOpen: () => void;
};

export const ConferenceFiltersTooltip = ({onOpen}: ConferenceFiltersTooltipProps) => (
    <Tooltip title="Filter les conférences">
        <IconButton onClick={onOpen} color="primary">
            <FilterIcon />
        </IconButton>
    </Tooltip>
);
