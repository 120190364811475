import React, {useState} from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Rejected} from "../data/State";

type ErrorNotificationProps = {
    error: Rejected;
};

export const ErrorNotification = ({error: {code, message}}: ErrorNotificationProps) => {
    const [open, setOpen] = useState(true);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar open={open} anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
            <Alert severity="error" onClose={onClose}>
                <b>{code}</b>: {message}
            </Alert>
        </Snackbar>
    );
};
