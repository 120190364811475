import React from "react";
import {Typography} from "@material-ui/core";
import {Level} from "../Level";
import {useOptions} from "../../app/Options";

type LevelTextProps = {
    levelId: string;
};

export const levelText = (level: Level) => `${level.label} ${level.emoji}`;

export const LevelText = ({levelId}: LevelTextProps) => {
    const {levelsById} = useOptions();
    const level = levelsById.get(levelId);
    return <Typography>{level ? levelText(level) : ""}</Typography>;
};
