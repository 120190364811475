import React from "react";
import {Talk} from "../Talk";
import {Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {InfoIcon} from "../../app/components/InfoIcon";

type TalkInfoTooltipProps = {
    talk: Talk;
};

const useStyles = makeStyles(() => ({
    text: {
        whiteSpace: "pre-line",
    },
}));

export const TalkInfoTooltip = ({talk}: TalkInfoTooltipProps) => {
    const classes = useStyles();
    return talk.info.length > 0 ? (
        <Tooltip title={<Typography className={classes.text}>{talk.info}</Typography>}>
            <InfoIcon color="primary" />
        </Tooltip>
    ) : (
        <></>
    );
};
