import firebase from "firebase";
import {Topic} from "./Topic";
import {firestore, firestoreDataConverter} from "../app/Firebase";

export const topicsCollection = firestore.collection("topics").withConverter(firestoreDataConverter<Topic>());

export const observeTopics = () => (
    setSuccess: (topics: Topic[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) =>
    topicsCollection
        .orderBy("label")
        .onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);

export const saveTopic = async (topic: Topic) => {
    await topicsCollection.doc(topic.id).set(topic);
    return topic;
};
