import React from "react";
import {Dialog} from "../../app/components/Dialog";
import {Talk} from "../Talk";
import {TalkDetail} from "./TalkDetail";

type TalkPreviewProps = {
    talk: Talk;
    onClose: () => void;
};

export const TalkPreview = ({talk, onClose}: TalkPreviewProps) => (
    <Dialog title={talk.title} onClose={onClose}>
        <TalkDetail talk={talk} />
    </Dialog>
);
