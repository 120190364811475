const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/*
    Inspired by firestore AutoId class which is unfortunately not exported
    - https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/misc.ts
    Generating ids in client side simplifies code:
    - there is no need to handle undefined id fields;
    - there is no need to define partial types using Omit, Partial, Exclude or Pick generic types.
*/
export const newId = () => {
    let newId = "";
    for (let i = 0; i < 20; i++) {
        const position = Math.floor(Math.random() * chars.length * 97) % chars.length;
        newId += chars.charAt(position);
    }
    return newId;
};

export type DocumentWithId = {
    id: string;
};

export type DocumentsById<DOCUMENT extends DocumentWithId> = Map<DOCUMENT["id"], DOCUMENT>;

export const documentsById = <DOCUMENT extends DocumentWithId>(documents: DOCUMENT[]): DocumentsById<DOCUMENT> =>
    new Map(documents.map(document => [document.id, document]));

export type CountById = {
    [id: string]: number;
};
