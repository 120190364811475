import {useCallback, useEffect, useState} from "react";
import {Conference} from "./Conference";
import {publishConference} from "./Conference.functions";
import {ConferenceFilters} from "./ConferenceFilters";
import {
    deleteConference,
    findConferences,
    observeConference,
    observeConferences,
    saveConference,
} from "./Conference.repository";
import {errorFromReason, initial, isResolved, pending, resolved, State} from "../app/data/State";
import {Submission} from "../submissions/Submission";
import {useCallbackState, useObserverState} from "../app/data/State.hooks";
import {ConferencePublication} from "./ConferencePublication";

export const useConferenceSearch = (filters: ConferenceFilters) => {
    const {afterDate, beforeDate} = filters;
    return useObserverState(observeConferences(filters), [afterDate, beforeDate]);
};

export const useConference = (conferenceId: Conference["id"]) =>
    useObserverState(observeConference(conferenceId), [conferenceId]);

export const useConferencesForSubmissions = (submissionsState: State<Submission[]>) => {
    const [state, setState] = useState<State<Conference[]>>(initial());
    useEffect(() => {
        if (!isResolved(submissionsState)) {
            setState(pending());
            return;
        }
        const submissions = submissionsState.result;
        const conferenceIds = submissions.map(({conferenceId}) => conferenceId);
        findConferences(conferenceIds)
            .then(conferences => setState(resolved(conferences)))
            .catch(reason => setState(errorFromReason(reason)));
    }, [submissionsState]);
    return state;
};

export const useConferenceSaveAction = () => useCallbackState(saveConference, []);

export const useConferenceDeleteAction = () => useCallbackState(deleteConference, []);

export const useConferencePublicationAction = (): [
    State<ConferencePublication>,
    (conference: Conference) => void,
    () => void,
] => {
    const [state, setState] = useState<State<ConferencePublication>>(initial());
    const action = useCallback((conference: Conference) => {
        setState(pending());
        publishConference(conference.id)
            .then(result => setState(resolved(result)))
            .catch(reason => setState(errorFromReason(reason)));
    }, []);
    const reset = () => setState(initial());
    return [state, action, reset];
};
