import React from "react";
import {List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {PublishButton} from "../../app/components/Button";
import {Dialog} from "../../app/components/Dialog";
import {isPending, isRejected, isResolved, State} from "../../app/data/State";
import {ErrorNotification} from "../../app/components/ErrorNotification";
import {Conference} from "../Conference";
import {ConferencePublication} from "../ConferencePublication";

type ConferencePublishProps = {
    conference: Conference;
    publicationState: State<ConferencePublication>;
    onClose: () => void;
    onPublish: () => void;
};

export const ConferencePublish = ({conference, publicationState, onClose, onPublish}: ConferencePublishProps) => {
    const isPublishing = isPending(publicationState);
    const isPublished = isResolved(publicationState);
    return (
        <Dialog
            title="Publier la conférence"
            actions={<PublishButton disabled={isPublished} loading={isPublishing} onClick={onPublish} />}
            onClose={onClose}
        >
            {!isResolved(publicationState) ? (
                <Typography>
                    La publication de {conference.title} synchronisera les évènements vers l&apos;Agenda Google et
                    publiera le programme vers Conf Companion.
                </Typography>
            ) : (
                <List>
                    <ListItem>
                        <ListItemText>
                            {publicationState.result.created}{" "}
                            {publicationState.result.created > 1 ? "évènements créés" : "évènement créé"}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            {publicationState.result.updated}{" "}
                            {publicationState.result.updated > 1 ? "évènements édités" : "évènement édité"}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            {publicationState.result.deleted}{" "}
                            {publicationState.result.deleted > 1 ? "évènements supprimés" : "évènement supprimé"}
                        </ListItemText>
                    </ListItem>
                </List>
            )}
            {isRejected(publicationState) ? <ErrorNotification error={publicationState} /> : undefined}
        </Dialog>
    );
};
