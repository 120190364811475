import firebase from "firebase/app";
import {Talk} from "./Talk";
import {TalkFilters} from "./TalkFilters";
import {firestoreDataConverter, firestore} from "../app/Firebase";
import {submissionsCollection} from "../submissions/Submission.repository";

export const talksCollection = firestore.collection("talks").withConverter(firestoreDataConverter<Talk>());

export const observeTalks = ({formatId, levelId, speakerIds, topicId}: TalkFilters) => (
    setSuccess: (talks: Talk[]) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) => {
    let query = talksCollection.orderBy("updatedAt", "desc");
    if (speakerIds.length > 0) query = query.where("speakerIds", "array-contains-any", speakerIds);
    if (formatId) query = query.where("formatId", "==", formatId);
    if (levelId) query = query.where("levelId", "==", levelId);
    if (topicId) query = query.where("topicId", "==", topicId);
    return query.onSnapshot(snapshot => setSuccess(snapshot.docs.map(doc => doc.data())), setFailure);
};

export const observeTalk = (talkId: Talk["id"]) => (
    setSuccess: (talk?: Talk) => void,
    setFailure: (error: firebase.FirebaseError) => void,
) => talksCollection.doc(talkId).onSnapshot(snapshot => setSuccess(snapshot.data()), setFailure);

export const findTalks = async (talkIds: Talk["id"][]) => {
    const snapshots = await Promise.all(talkIds.map(talkId => talksCollection.doc(talkId).get()));
    return snapshots.map(talk => talk.data()).filter((talk): talk is Talk => talk !== undefined);
};

export const saveTalk = async (talk: Talk) => {
    await talksCollection.doc(talk.id).set(talk);
    return talk;
};

export const deleteTalk = async (talk: Talk) => {
    const submissionsQuery = await submissionsCollection.where("talkId", "==", talk.id).get();
    const batch = firestore.batch();
    batch.delete(talksCollection.doc(talk.id));
    submissionsQuery.docs.forEach(({ref}) => batch.delete(ref));
    await batch.commit();
    return talk;
};
