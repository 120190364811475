import React from "react";
import {Typography} from "@material-ui/core";
import {ConferenceFilters} from "../ConferenceFilters";
import dayjs from "dayjs";

type ConferenceFiltersTextProps = {
    filters: ConferenceFilters;
};

export const ConferenceFiltersText = ({filters}: ConferenceFiltersTextProps) => (
    <Typography>
        Conférences du {dayjs(filters.afterDate).format("L")} au {dayjs(filters.beforeDate).format("L")}
    </Typography>
);
