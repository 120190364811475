import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LinearProgress} from "@material-ui/core";
import {useRoutes} from "../../Routes";
import {useAuthentication} from "../../auth/Authentication.provider";
import {isResolved, isRejected, isInitialOrPending} from "../../app/data/State";
import {conferenceBelongsTo} from "../Conference";
import {ConferenceView} from "../components/ConferenceView";
import {
    useConference,
    useConferenceDeleteAction,
    useConferencePublicationAction,
    useConferenceSaveAction,
} from "../Conference.hooks";
import {useSubmissionsForConference} from "../../submissions/Submission.hooks";
import {useTalksForSubmissions} from "../../talks/Talk.hooks";
import {ErrorNotifications} from "../../app/components/ErrorNotifications";
import {ConferenceDetail} from "../components/ConferenceDetail";
import {ConferenceTabs} from "../components/ConferenceTabs";
import {ConferenceSelectionEditor} from "../components/ConferenceSelectionEditor";
import {ConferenceText} from "../components/ConferenceText";
import {Page} from "../../app/components/Page";
import {ConferencePlanningEditor} from "../components/ConferencePlanningEditor";
import {ConferenceForm} from "../components/ConferenceForm";
import {ConferenceDelete} from "../components/ConferenceDelete";
import {ConferencePublish} from "../components/ConferencePublish";

type ConferencePageParams = {
    conferenceId: string;
    view?: ConferenceView;
};

export const ConferencePage = () => {
    const routes = useRoutes();
    const {conferenceId, view = "summary"} = useParams<ConferencePageParams>();
    const authentication = useAuthentication();
    const conferenceState = useConference(conferenceId);
    const submissionsState = useSubmissionsForConference(conferenceId);
    const talksState = useTalksForSubmissions(submissionsState);
    const [updateFormIsOpen, setUpdateFormIsOpen] = useState(false);
    const [updateState, updateAction] = useConferenceSaveAction();
    const [deleteFormIsOpen, setDeleteFormIsOpen] = useState(false);
    const [deleteState, deleteAction] = useConferenceDeleteAction();
    const [publicationFormIsOpen, setPublicationFormIsOpen] = useState(false);
    const [publicationState, publicationAction, publicationReset] = useConferencePublicationAction();

    useEffect(() => {
        if (isResolved(updateState)) {
            setUpdateFormIsOpen(false);
        }
    }, [updateState]);

    useEffect(() => {
        if (isResolved(deleteState)) {
            setDeleteFormIsOpen(false);
            routes.conferences();
        }
    }, [deleteState]);

    if (isInitialOrPending(conferenceState) || isInitialOrPending(submissionsState) || isInitialOrPending(talksState)) {
        return <LinearProgress />;
    }

    if (isRejected(conferenceState) || isRejected(submissionsState) || isRejected(talksState)) {
        return <ErrorNotifications errorOrDoneStates={[conferenceState, submissionsState, talksState]} />;
    }

    if (!conferenceState.result) {
        return null;
    }

    const conference = conferenceState.result;
    const submissions = submissionsState.result;
    const talks = talksState.result;

    const readonly = !authentication.isOrganizer || !conferenceBelongsTo(conference, authentication.user);

    return (
        <Page
            title={<ConferenceText variant="h6" conference={conference} />}
            toolbar={<ConferenceTabs view={view} setView={view => routes.conference(conferenceId, view)} />}
        >
            {view === "summary" ? (
                <ConferenceDetail
                    readonly={readonly}
                    conference={conference}
                    talks={talks}
                    onUpdate={() => setUpdateFormIsOpen(true)}
                    onDelete={() => setDeleteFormIsOpen(true)}
                />
            ) : view === "selection" ? (
                <ConferenceSelectionEditor
                    readonly={readonly}
                    conference={conference}
                    submissions={submissions}
                    talks={talks}
                    saveState={updateState}
                    onSave={updateAction}
                />
            ) : (
                <ConferencePlanningEditor
                    readonly={readonly}
                    conference={conference}
                    talks={talks}
                    saveState={updateState}
                    onSave={updateAction}
                    onPublish={() => setPublicationFormIsOpen(true)}
                />
            )}
            {updateFormIsOpen ? (
                <ConferenceForm
                    conference={conference}
                    saveState={updateState}
                    onSave={updateAction}
                    onClose={() => setUpdateFormIsOpen(false)}
                />
            ) : undefined}
            {deleteFormIsOpen ? (
                <ConferenceDelete
                    conference={conference}
                    deleteState={deleteState}
                    onDelete={deleteAction}
                    onClose={() => setDeleteFormIsOpen(false)}
                />
            ) : undefined}
            {publicationFormIsOpen ? (
                <ConferencePublish
                    conference={conference}
                    publicationState={publicationState}
                    onClose={() => {
                        setPublicationFormIsOpen(false);
                        publicationReset();
                    }}
                    onPublish={() => publicationAction(conference)}
                />
            ) : undefined}
        </Page>
    );
};
