import React, {useEffect, useState} from "react";
import {Toolbar, Typography} from "@material-ui/core";
import {RoomList} from "../components/RoomList";
import {useRooms, useRoomSaveAction} from "../Room.hooks";
import {useAuthentication} from "../../auth/Authentication.provider";
import {Page} from "../../app/components/Page";
import {Loadable} from "../../app/components/Loadable";
import {CreateButton} from "../../app/components/Button";
import {RoomForm} from "../components/RoomForm";
import {Room} from "../Room";
import {isResolved} from "../../app/data/State";

export const RoomListPage = () => {
    const roomsState = useRooms();
    const authentication = useAuthentication();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [updateFormRoom, setUpdateFormRoom] = useState<Room | undefined>(undefined);
    const [saveState, saveAction] = useRoomSaveAction();

    useEffect(() => {
        if (isResolved(saveState)) {
            setCreateFormIsOpen(false);
            setUpdateFormRoom(undefined);
        }
    }, [saveState]);

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Salles</Typography>}
            toolbar={
                readonly ? undefined : (
                    <Toolbar>
                        <CreateButton onClick={() => setCreateFormIsOpen(true)} />
                    </Toolbar>
                )
            }
        >
            <Loadable state={roomsState}>
                {rooms => <RoomList readonly={readonly} rooms={rooms} onUpdate={setUpdateFormRoom} />}
            </Loadable>
            {createFormIsOpen ? (
                <RoomForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {updateFormRoom ? (
                <RoomForm
                    room={updateFormRoom}
                    saveState={saveState}
                    onSave={saveAction}
                    onClose={() => setUpdateFormRoom(undefined)}
                />
            ) : undefined}
        </Page>
    );
};
