import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Toolbar, Typography} from "@material-ui/core";
import {useRoutes} from "../../Routes";
import {ConferenceList} from "../components/ConferenceList";
import {ConferenceFiltersForm, ConferenceFiltersTooltip} from "../components/ConferenceFiltersForm";
import {ConferenceFilters} from "../ConferenceFilters";
import {useAuthentication} from "../../auth/Authentication.provider";
import {Page} from "../../app/components/Page";
import {CreateButton} from "../../app/components/Button";
import {ConferenceFiltersText} from "../components/ConferenceFiltersText";
import {conferenceActions, useConferenceFilters} from "../Conference.slice";
import {useConferenceSaveAction, useConferenceSearch} from "../Conference.hooks";
import {Loadable} from "../../app/components/Loadable";
import {Filler} from "../../app/components/Filler";
import {ConferenceForm} from "../components/ConferenceForm";
import {isResolved} from "../../app/data/State";

export const ConferenceListPage = () => {
    const routes = useRoutes();
    const authentication = useAuthentication();
    const filters = useConferenceFilters();
    const searchState = useConferenceSearch(filters);
    const dispatch = useDispatch();
    const [createFormIsOpen, setCreateFormIsOpen] = useState(false);
    const [saveState, saveAction] = useConferenceSaveAction();
    const [filterFormIsOpen, setFilterFormIsOpen] = useState(false);

    useEffect(() => {
        if (isResolved(saveState)) {
            routes.conference(saveState.result.id);
        }
    }, [saveState]);

    const setFilters = (filters: ConferenceFilters) => dispatch(conferenceActions.setFilters(filters));

    const readonly = !authentication.isOrganizer;

    return (
        <Page
            title={<Typography variant="h6">Conférences</Typography>}
            toolbar={
                <Toolbar>
                    {readonly ? undefined : <CreateButton onClick={() => setCreateFormIsOpen(true)} />}
                    <Filler />
                    <ConferenceFiltersText filters={filters} />
                    <ConferenceFiltersTooltip onOpen={() => setFilterFormIsOpen(true)} />
                </Toolbar>
            }
        >
            <Loadable state={searchState}>
                {conferences => (
                    <ConferenceList
                        conferences={conferences}
                        onSelect={conference => routes.conference(conference.id)}
                    />
                )}
            </Loadable>
            {createFormIsOpen ? (
                <ConferenceForm saveState={saveState} onSave={saveAction} onClose={() => setCreateFormIsOpen(false)} />
            ) : undefined}
            {filterFormIsOpen ? (
                <ConferenceFiltersForm
                    filters={filters}
                    onApply={setFilters}
                    onClose={() => setFilterFormIsOpen(false)}
                />
            ) : undefined}
        </Page>
    );
};
